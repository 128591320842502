import React, { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineCheck } from "react-icons/md";
import Footer from "../components/Footer";
import SingleQuestion from "./SingleQuestion";
import axios from "axios";
import menu from "../assets/menu.png";
import { useGlobalContext } from "../context";
import { initialData } from "./questionsConfig";
export default function Questions() {
  const [id, setID] = useState(0);
  const [QuesID, setQuesID] = useState(0);
  const [MultiLimit, setMultiLimit] = useState([]);
  const [MultiLimitSub, setMultiLimitSub] = useState([]);
  const [CheckID, setUpdateCheck] = useState();
  const [goalLevel, setgoalLevel] = useState();
  const [stuDetails, setStuDetails] = useState({});
  const [singleQuestion, setSingleQuestion] = useState({
    title: "",
    question: [],
    select: "",
  });
  const [currentNav, setCurrentNav] = useState(0);

  var { filteredJSON, setFilteredJSON, update } = useGlobalContext();



  const QUESTIONS = [];

  initialData.map((item) => {
    item.questions.map((it) => {
      QUESTIONS.push(it.question);
    });
  });
  const [Data, setData] = useState(initialData);

  const setNextQuestion = (id, QuesID, grade) => {
    if (id == 0 && QuesID == 0) {
      var toShowOptions = [];
      initialData[id].questions[QuesID].options.forEach((value, index) => {
        if (value.check === true) {
          toShowOptions = toShowOptions.concat(value.show);
        }
      });
      initialData[id].questions[QuesID + 2].options.forEach((value, index) => {
        initialData[id].questions[QuesID + 2].options[index].check = false;
        if (toShowOptions.includes(index)) {
          initialData[id].questions[QuesID + 2].options[index].isHidden = false;
        } else {
          initialData[id].questions[QuesID + 2].options[index].isHidden = true;
        }
      });
    }
    if (id == 1 && QuesID == 1) {
      initialData[id].questions[QuesID + 1].options.forEach((value, index) => {
        if (
          initialData[id].questions[QuesID + 1].options[index].level <= grade
        ) {
          initialData[id].questions[QuesID + 1].options[index].isHidden = false;
        } else {
          initialData[id].questions[QuesID + 1].options[index].isHidden = true;
        }
      });
    }

    if (id == 1 && QuesID == 4) {
      var option = [];
      var dependentLevel = initialData[id].questions[
        initialData[id].questions[QuesID + 1].dependQuestion
      ].options.filter((x) => x.check === true);
      initialData[id].questions[QuesID + 1].suberGoals.forEach(
        (value, index) => {
          if (
            value.level < grade + 1 &&
            value.level >= dependentLevel[0].level
          ) {
            var isPresenrt = option.filter((x) => x.value == value.title);
            if (!isPresenrt.length > 0) {
              var subGoals = [];
              value.subGoals.forEach((x) => {
                subGoals.push({
                  text: x,
                  check: false,
                });
              });
              option.push({
                value: value.title,
                text: subGoals,
                check: false,
              });
            } else {
              var superGoalIndex = option.findIndex(
                (p) => p.value == value.title
              );
              value.subGoals.forEach((x) => {
                var exsitdGoal = option[superGoalIndex].text.filter(
                  (goal) => goal.text == x
                );
                if (!exsitdGoal.length > 0) {
                  option[superGoalIndex].text.push({
                    text: x,
                    check: false,
                  });
                }
              });
            }
          }
        }
      );
      initialData[id].questions[QuesID + 1].goalQues = option;
    }

    if (id == 1 && QuesID == 5) {
      var allCheckded = Data[id].questions[QuesID].goalQues.filter(
        (x) => x.check === true
      );
      var allAnsers = [];
      allCheckded.forEach((value, index) => {
        value.text.forEach((texval, point) => {
          if (texval.check === true) allAnsers.push(texval.text);
        });
      });
      var option = [];
      var dependentLevel = initialData[id].questions[
        initialData[id].questions[QuesID + 1].dependQuestion
      ].options.filter((x) => x.check === true);
      initialData[id].questions[QuesID + 1].suberGoals.forEach(
        (value, index) => {
          var isPresenrt = option.filter((x) => x.value == value.title);
          if (!isPresenrt.length > 0) {
            var subGoals = [];
            value.subGoals.forEach((x) => {
              var exsitdGoal = subGoals.filter((goal) => goal.text == x);
              if (!exsitdGoal.length > 0) {
                if (!allAnsers.includes(x)) {
                  subGoals.push({
                    text: x,
                    check: false,
                  });
                }
              }
            });
            option.push({
              value: value.title,
              text: subGoals,
              check: false,
            });
          } else {
            var superGoalIndex = option.findIndex(
              (p) => p.value == value.title
            );
            value.subGoals.forEach((x) => {
              var exsitdGoal = option[superGoalIndex].text.filter(
                (goal) => goal.text == x.title
              );
              if (!exsitdGoal.length > 0) {
                if (
                  x.level <= grade + 1 &&
                  x.level >= dependentLevel[0].level
                ) {
                  if (!allAnsers.includes(x.title)) {
                    option[superGoalIndex].text.push({
                      text: x.title,
                      check: false,
                    });
                  }
                }
              }
            });
          }
        }
      );

      initialData[id].questions[QuesID + 1].goalQues = option;
    }


    if (id == 2 && QuesID == 1) {
      initialData[id].questions[QuesID + 1].options.forEach((value, index) => {
        if (
          initialData[id].questions[QuesID + 1].options[index].level <= grade
        ) {
          initialData[id].questions[QuesID + 1].options[index].isHidden = false;
        } else {
          initialData[id].questions[QuesID + 1].options[index].isHidden = true;
        }
      });
    }

    if (id == 2 && QuesID == 4) {
      var option = [];
      var dependentLevel = initialData[id].questions[
        initialData[id].questions[QuesID + 1].dependQuestion
      ].options.filter((x) => x.check === true);
      initialData[id].questions[QuesID + 1].suberGoals.forEach(
        (value, index) => {
          if (
            value.level < grade + 1 &&
            value.level >= dependentLevel[0].level
          ) {
            var isPresenrt = option.filter((x) => x.value == value.title);
            if (!isPresenrt.length > 0) {
              var subGoals = [];
              value.subGoals.forEach((x) => {
                subGoals.push({
                  text: x,
                  check: false,
                });
              });
              option.push({
                value: value.title,
                text: subGoals,
                check: false,
              });
            } else {
              var superGoalIndex = option.findIndex(
                (p) => p.value == value.title
              );
              value.subGoals.forEach((x) => {
                var exsitdGoal = option[superGoalIndex].text.filter(
                  (goal) => goal.text == x
                );
                if (!exsitdGoal.length > 0) {
                  option[superGoalIndex].text.push({
                    text: x,
                    check: false,
                  });
                }
              });
            }
          }
        }
      );
      initialData[id].questions[QuesID + 1].goalQues = option;
    }

    if (id == 2 && QuesID == 5) {
      var allAnsers = [];
      allCheckded?.forEach((value, index) => {
        value.text.forEach((texval, point) => {
          if (texval.check === true) allAnsers.push(texval.text);
        });
      });
      var option = [];
      var dependentLevel = initialData[id].questions[
        initialData[id].questions[QuesID + 1].dependQuestion
      ].options.filter((x) => x.check === true);
      initialData[id].questions[QuesID + 1].suberGoals.forEach(
        (value, index) => {
          if (
            value.level <= grade + 1 &&
            value.level >= dependentLevel[0].level
          ) {
            var isPresenrt = option.filter((x) => x.value == value.title);
            if (!isPresenrt.length > 0) {
              var subGoals = [];
              value.subGoals.forEach((x) => {
                var exsitdGoal = subGoals.filter((goal) => goal.text == x);
                if (!exsitdGoal.length > 0) {
                  if (!allAnsers.includes(x)) {
                    subGoals.push({
                      text: x,
                      check: false,
                    });
                  }
                }
              });
              option.push({
                value: value.title,
                text: subGoals,
                check: false,
              });
            } else {
              var superGoalIndex = option.findIndex(
                (p) => p.value == value.title
              );
              value.subGoals.forEach((x) => {
                var exsitdGoal = option[superGoalIndex].text.filter(
                  (goal) => goal.text == x
                );
                if (!exsitdGoal.length > 0) {
                  if (!allAnsers.includes(x)) {
                    option[superGoalIndex].text.push({
                      text: x,
                      check: false,
                    });
                  }
                }
              });
            }
          }
        }
      );
      initialData[id].questions[QuesID + 1].goalQues = option;
    }

    if (id == 3 && QuesID == 0) {
      initialData[id].questions[QuesID + 1].options.forEach((value, index) => {
        if (
          initialData[id].questions[QuesID + 1].options[index].level <= grade
        ) {
          initialData[id].questions[QuesID + 1].options[index].isHidden = false;
        } else {
          initialData[id].questions[QuesID + 1].options[index].isHidden = true;
        }
      });
    }
    if (id == 3 && QuesID == 3) {
      var option = [];
      var dependentLevel = initialData[id].questions[
        initialData[id].questions[QuesID + 1].dependQuestion
      ].options.filter((x) => x.check === true);
      initialData[id].questions[QuesID + 1].suberGoals.forEach(
        (value, index) => {
          if (
            value.level < grade + 1 &&
            value.level >= dependentLevel[0].level
          ) {
            var isPresenrt = option.filter((x) => x.value == value.title);
            if (!isPresenrt.length > 0) {
              var subGoals = [];
              value.subGoals.forEach((x) => {
                subGoals.push({
                  text: x,
                  check: false,
                });
              });
              option.push({
                value: value.title,
                text: subGoals,
                check: false,
              });
            } else {
              var superGoalIndex = option.findIndex(
                (p) => p.value == value.title
              );
              value.subGoals.forEach((x) => {
                var exsitdGoal = option[superGoalIndex].text.filter(
                  (goal) => goal.text == x
                );
                if (!exsitdGoal.length > 0) {
                  option[superGoalIndex].text.push({
                    text: x,
                    check: false,
                  });
                }
              });
            }
          }
        }
      );
      initialData[id].questions[QuesID + 1].goalQues = option;
    }

    if (id == 3 && QuesID == 4) {
      var allAnsers = [];
      allCheckded?.forEach((value, index) => {
        value.text.forEach((texval, point) => {
          if (texval.check === true) allAnsers.push(texval.text);
        });
      });
      var option = [];
      var dependentLevel = initialData[id].questions[
        initialData[id].questions[QuesID + 1].dependQuestion
      ].options.filter((x) => x.check === true);
      initialData[id].questions[QuesID + 1].suberGoals.forEach(
        (value, index) => {
          var checkInHide = initialData[id].questions[
            QuesID + 1
          ].removeSuperGoals.filter((x) => x.hide == value.title);
          var toAdd = true;
          if (checkInHide.length > 0) {
            allAnsers.every((x) => {
              if (checkInHide[0].titles.includes(x)) {
                toAdd = false;
                return false;
              }
              return true;
            });
          }
          if (
            value.level <= grade + 1 &&
            value.level >= dependentLevel[0].level &&
            toAdd
          ) {
            var isPresenrt = option.filter((x) => x.value == value.title);
            if (!isPresenrt.length > 0) {
              var subGoals = [];
              value.subGoals.forEach((x) => {
                var exsitdGoal = subGoals.filter((goal) => goal.text == x);
                if (!exsitdGoal.length > 0) {
                  if (!allAnsers.includes(x)) {
                    subGoals.push({
                      text: x,
                      check: false,
                    });
                  }
                }
              });
              option.push({
                value: value.title,
                text: subGoals,
                check: false,
              });
            } else {
              var superGoalIndex = option.findIndex(
                (p) => p.value == value.title
              );
              value.subGoals.forEach((x) => {
                var exsitdGoal = option[superGoalIndex].text.filter(
                  (goal) => goal.text == x
                );
                if (!exsitdGoal.length > 0) {
                  if (!allAnsers.includes(x)) {
                    option[superGoalIndex].text.push({
                      text: x,
                      check: false,
                    });
                  }
                }
              });
            }
          }
        }
      );
      option = option.filter((x) => x.text.length > 0);
      initialData[id].questions[QuesID + 1].goalQues = option;
    }

    if (id == 4 && QuesID == 3) {
      var allChecked = initialData[id].questions[QuesID].options.filter(
        (x) => x.check === true
      );
      var toHideOptions = [];
      allChecked?.forEach((value, index) => {
        toHideOptions = toHideOptions.concat(value.doHide);
      });
      initialData[id].questions[QuesID + 1].options.forEach((value, index) => {
        initialData[id].questions[QuesID + 1].options[index].check = false;
        if (toHideOptions.includes(index)) {
          initialData[id].questions[QuesID + 1].options[index].isHidden = true;
        } else {
          initialData[id].questions[QuesID + 1].options[index].isHidden = false;
        }
      });
    }

    if (id == 4 && QuesID == 5) {
      var toHideOptions = [];
      allChecked?.forEach((value, index) => {
        toHideOptions = toHideOptions.concat(value.doHide);
      });
      initialData[id].questions[QuesID + 1].options.forEach((value, index) => {
        initialData[id].questions[QuesID + 1].options[index].check = false;
        if (toHideOptions.includes(index)) {
          initialData[id].questions[QuesID + 1].options[index].isHidden = true;
        } else {
          initialData[id].questions[QuesID + 1].options[index].isHidden = false;
        }
      });
    }

    if (id == 5 && QuesID == 0) {
      if (allChecked && allChecked.length == 1 && allChecked[0].value == "none") {
        initialData[id].questions[1].answered = false;
        initialData[id].questions[2].answered = false;
        initialData[id].questions[3].answered = false;
        initialData[id].questions[4].answered = false;
      } else {
      }
    }

    if (id == 5 && QuesID == 1) {
      var allChecked = initialData[id].questions[QuesID].options.filter(
        (x) => x.check === true
      );
      var option = [];
      var checkedLevel = initialData[id].questions[
        initialData[id].questions[QuesID + 1].dependQuestion
      ].options.filter((x) => x.check === true);

      var DependentLevel = [];
      checkedLevel.forEach((value, index) => {
        if (value.check === true) DependentLevel.push(value.level);
      });

      initialData[id].questions[QuesID + 1].suberGoals.forEach(
        (value, index) => {
          if (DependentLevel.includes(value.level)) {
            var subGoals = [];
            value.subGoals.forEach((x) => {
              subGoals.push({
                text: x,
                check: false,
              });
            });
            option.push({
              value: value.title,
              text: subGoals,
              check: false,
            });
          }
        }
      );

      initialData[id].questions[QuesID + 1].goalQues = option;
    }

    if (id == 5 && QuesID == 2) {
      var option = [];
      var checkedLevel = initialData[id].questions[
        initialData[id].questions[QuesID + 1].dependQuestion
      ].options.filter((x) => x.check === true);

      var DependentLevel = [];
      checkedLevel.forEach((value, index) => {
        if (value.check === true) DependentLevel.push(value.level);
      });

      initialData[id].questions[QuesID + 1].suberGoals.forEach(
        (value, index) => {
          if (DependentLevel.includes(value.level)) {
            var subGoals = [];
            value.subGoals.forEach((x) => {
              subGoals.push({
                text: x,
                check: false,
              });
            });
            option.push({
              value: value.title,
              text: subGoals,
              check: false,
            });
          }
        }
      );

      initialData[id].questions[QuesID + 1].goalQues = option;
    }

    if (id == 5 && QuesID == 3) {
      var allCheckded = initialData[id].questions[QuesID].goalQues.filter(
        (x) => x.check === true
      );
      var option = [];
      var checkedLevel = initialData[id].questions[
        initialData[id].questions[QuesID + 1].dependQuestion
      ].options.filter((x) => x.check === true);

      var DependentLevel = [];
      var allanswer = [];
      allCheckded.forEach((value, index) => {
        value.text.forEach((val, point) => {
          if (val.check === true) allanswer.push(val.text);
        });
        //if (value.check === true) DependentLevel.push(value.level);
      });

      checkedLevel.forEach((value, index) => {
        if (value.check === true) DependentLevel.push(value.level);
      });

      initialData[id].questions[QuesID + 1].suberGoals.forEach(
        (value, index) => {
          if (DependentLevel.includes(value.level)) {
            var subGoals = [];
            value.subGoals.forEach((x) => {
              if (x.hideIf.length > 0) {
                if (!allanswer.includes(x.hideIf[0])) {
                  subGoals.push({
                    text: x.title,
                    check: false,
                  });
                }
              } else {
                subGoals.push({
                  text: x.title,
                  check: false,
                });
              }
            });
            option.push({
              value: value.title,
              text: subGoals,
              check: false,
            });
          }
        }
      );

      initialData[id].questions[QuesID + 1].goalQues = option;
    }
  };

  const GradeLevels = [
    ["Nursery"],
    ["Kindergarten", "Pre1-A"],
    ["1",1],
    ["2",2],
    ["3",3],
    ["4",4],
    ["5",5],
    ["6",6],
    ["7",7],
    ["8",8],
    ["9",9],
    ["10",10],
    ["11",11],
    ["12",12],
  ];

  const setQuestions = (response) => {
    setFilteredJSON(response.questions);
    let Responsedata = (response.questions || []).sort((q1, q2) => q1.questionId - q2.questionId);

    const lastAnsweredQuestionIndex = Responsedata.findLastIndex(
      (question) => !!question.answeres?.length
    )
    const lastAnsweredQuestion = Responsedata[Math.max(lastAnsweredQuestionIndex, 0)]
    const lastAnsweredParentId = initialData.findIndex(
      (x) => x.title.toLowerCase() === lastAnsweredQuestion.groupName.toLowerCase()
    );

    setID(lastAnsweredParentId);
    setQuesID(lastAnsweredQuestion?.externalId);

    for (const questionObj of Responsedata) {
      let parent = initialData.findIndex(
        (x) => x.title.toLowerCase() === questionObj.groupName.toLowerCase()
      );

      let quesId = questionObj.externalId;

      if (initialData[parent].questions[quesId].select !== "Accordian") {
        for (const answer of questionObj.answeres) {
          initialData[parent].questions[quesId].options.forEach((val, ind) => {
            initialData[parent].questions[quesId].options[ind].value =
            val.value.replace(/name/g, response.FirstName );

          });

          if(response.Gender.toLowerCase() === "male" ){
            initialData[parent].questions[quesId].options.forEach((val, ind) => {
              initialData[parent].questions[quesId].options[ind].value =
                val.value.replace("P1","he");
            });
            initialData[parent].questions[quesId].options.forEach((val, ind) => {
              initialData[parent].questions[quesId].options[ind].value =
                val.value.replace("P2","him");
            });
            initialData[parent].questions[quesId].options.forEach((val, ind) => {
              initialData[parent].questions[quesId].options[ind].value =
                val.value.replace("P3","his");
            });
          }else{
            initialData[parent].questions[quesId].options.forEach((val, ind) => {
              initialData[parent].questions[quesId].options[ind].value =
                val.value.replace("P1","she");
            });
            initialData[parent].questions[quesId].options.forEach((val, ind) => {
              initialData[parent].questions[quesId].options[ind].value =
                val.value.replace("P2","her");
            });
            initialData[parent].questions[quesId].options.forEach((val, ind) => {
              initialData[parent].questions[quesId].options[ind].value =
                val.value.replace("P2","her")
            });
          }


          let findOptionIndex = initialData[parent].questions[
            quesId
          ].options.findIndex(
            (x) => {
              return x.value.toLowerCase() === answer.toLowerCase();
            }
          );

          if (findOptionIndex > -1) {
            initialData[parent].questions[quesId].answered = true;
            initialData[parent].questions[quesId].options[
              findOptionIndex
            ].check = true;
          }
        }
      } else {
        for (const answer of questionObj.answeres) {

          let parent_index = initialData[parent].questions[
            quesId
          ].goalQues.findIndex(
            (x) => {
              return  x.value.toLowerCase() == answer.value.toLowerCase()
            }
          );

          if (parent_index > -1) {
            initialData[parent].questions[quesId].answered = true;
            initialData[parent].questions[quesId].goalQues[
              parent_index
            ].check = true;
          }




          const replace = (value) => {
            if (response.Gender.toLowerCase() === "male") {
              return value.replace("P1","he").replace("P2","him").replace("P3","his").toLowerCase();
            } else {
              return value.replace("P1","she").replace("P2","her").toLowerCase();
            }
          }

          if (answer.subAnswers && parent_index > -1) {
            for (const subAnswer of answer.subAnswers) {
              let child_index = initialData[parent].questions[quesId].goalQues[
                parent_index
              ].text.findIndex(
                (x) => {
                  return replace(x.text) == subAnswer.toLowerCase();
                }
              );

              if (child_index > -1) {
                initialData[parent].questions[quesId].goalQues[parent_index].text[
                  child_index
                ].check = true;
              }


            }
          }
        }
      }

      let grade = GradeLevels.findIndex((x) => x.includes(response.Grade));

      setNextQuestion(parent, quesId, grade);
    }


    const initialDataWithLimitFlagUpdated = initialData.map(
      (group) => ({
        ...group,
        questions: group.questions.map((question) => {
          const answeredQuestionDetails = Responsedata.find((answeredQuestion) => answeredQuestion.groupName === group.title && answeredQuestion.questionId === question.questionID)

          if (!answeredQuestionDetails || !answeredQuestionDetails.answeres?.length) return question

          if (question.select !== 'Multi') return { ...question, answered: true }

          const limit = question.max <= answeredQuestionDetails.answeres.length

          return ({
            ...question,
            answered: true,
            limit,
          })
        })
      })
    )

    setData(initialDataWithLimitFlagUpdated);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const url = `https://31zctjiomj.execute-api.us-east-1.amazonaws.com/default/enhacereport?StudentID=${searchParams.get(
      "StudentID"
    )}&Token=${searchParams.get("Token")}`;

    axios
      .get(url)
      .then((res) => {
        setStuDetails(res.data);

        if (res.data.questions.length) {
          setQuestions(res.data);
          // setFilteredJSON(res.data.questions)
        } else {
          setData(initialData);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const titles = Data.map((Titles) => Titles.title);
    const Questions = Data.map((question) => question.questions);
    const select = Questions[id].map((selectValue) => selectValue.select);
    setData(Data);
    setSingleQuestion({
      ...singleQuestion,
      title: titles[id],
      question: Questions[id],
      select: select[QuesID],
    });
    setCurrentNav(id);

    //  const gradeUpdate = Data[1].questions[2].options.filter(x => x.level < grade)
    //  Data[1].questions[2].options = gradeUpdate
  }, [id, QuesID, MultiLimit, goalLevel, MultiLimitSub]);

  const handleSingleQuestion = (i, parent) => {
    console.log(i, parent, id, "<----INDEX MENU");
    const question = Data[parent].questions[i];
    // setCurrentSelected(currentSelected.push([{ques: i, group: parent }]))
    if (question.answered) {
      setQuesID(i);
      setID(parent);
    } else {
      console.log(i, parent, "<----INDEX MENU");
    }

    // const titles = Data.map((Titles) => Titles.title);
    // const Questions = Data.map((question) => question.questions);
    // const select = Questions[id].map((selectValue) => selectValue.select);
    // setSingleQuestion({
    //   ...singleQuestion,
    //   title: titles[id],
    //   question: Questions[id],
    //   select: select[QuesID],
    // });
  };

  const handleOpen = (index) => {
    //if(index < 6)
    setCurrentNav(index);
    //setID(index)
  };


  return (
    <>
      <div className="flex flex-row studentContent">
        <div
          className="flex flex-col studentContent-Left"
          id="studentContentLeft"
        >
          <div className="menuTop flex justify-between items-center">
            <div className="menuIcon">
              <a className="menuInner" onClick={menuFunction}>
                <img src={menu} />
                <p>Menu </p>
              </a>
            </div>
            <div className="CloseIcon-Wrap">
              <a className="CloseIcon" onClick={closeFunction}></a>
            </div>
            <div className="menuText" id="MenuQuestion">
              <p>{`${QUESTIONS.length + 1} questions`}</p>
            </div>
          </div>
          <ul className="list-none navTop" id="menuItems">
            {Data?.map((titles, index) => (
              <li className="" onClick={() => handleOpen(index)}>
                <div className="nav-Wrapper">
                  <span>{titles?.title}</span>
                  <span>{titles?.questions.length}</span>
                  <div className="inline-block float-right rightIcon">
                    <IoIosArrowDown className="" />
                  </div>
                </div>
                {/* <p className="flex flex-row-reverse -m-4">5</p> */}
                <div
                  style={
                    index == currentNav
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  {titles.questions?.map((questions, i) => {
                    if (!questions.isRandom) {
                      return (
                        <ul>
                          <li
                            className={
                              QuesID === i && id === index
                                ? "text-xs text-[#DE706C] leading-8 wrap-text"
                                : "text-xs text-[#607889] leading-8 wrap-text"
                            }
                            onClick={() => handleSingleQuestion(i, index)}
                          >
                            <span>{questions.subTitle}</span>


                            <div
                              id="answeredOption"
                              className={
                                questions.answered === true
                                  ? `inline-block float-right answred rightIcon`
                                  : `inline-block float-right rightIcon`
                              }
                            >
                              <MdOutlineCheck className="" />
                            </div>
                          </li>
                        </ul>
                      );
                    }
                  })}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <SingleQuestion
          singleQuestion={singleQuestion}
          QuesID={QuesID}
          setData={setData}
          Data={Data}
          ID={id}
          setMultiLimit={setMultiLimit}
          MultiLimit={MultiLimit}
          CheckID={CheckID}
          setUpdateCheck={setUpdateCheck}
          goalLevel={goalLevel}
          setgoalLevel={setgoalLevel}
          MultiLimitSub={MultiLimitSub}
          setMultiLimitSub={setMultiLimitSub}
          stuDetails={stuDetails}
        />
      </div>
      <Footer
        setQuesID={setQuesID}
        QuesID={QuesID}
        setID={setID}
        id={id}
        singleQuestion={singleQuestion}
        MultiLimit={MultiLimit}
        setMultiLimit={setMultiLimit}
        CheckID={CheckID}
        setUpdateCheck={setUpdateCheck}
        setData={setData}
        Data={Data}
        goalLevel={goalLevel}
        setgoalLevel={setgoalLevel}
        MultiLimitSub={MultiLimitSub}
        setMultiLimitSub={setMultiLimitSub}
        stuDetails={stuDetails}
        QUESTIONS={QUESTIONS}
        initialData={initialData}
        // filteredJSON={filteredJSON}
        // setFilter={setFilter}
      />
    </>
  );
}

function menuFunction() {
  var x = document.getElementById("menuItems");
  var y = document.getElementById("MenuQuestion");
  var z = document.getElementById("studentContentLeft");
  if (x.style.display === "none") {
    x.style.display = "block";
    y.style.display = "block";
    z.classList.remove("openMenu");
  } else {
    x.style.display = "none";
    y.style.display = "none";
    z.classList.add("openMenu");
  }
}

function closeFunction() {
  var x = document.getElementById("menuItems");
  var y = document.getElementById("MenuQuestion");
  var z = document.getElementById("studentContentLeft");
  x.style.display = "block";
  y.style.display = "block";
  z.classList.remove("openMenu");
}