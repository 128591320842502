import React, { useState, useRef, useEffect } from "react";
import ChevronClosed from "../assets/ChevronClosed.svg";
import ChevronOpen from "../assets/ChevronOpen.svg";
import check from "../assets/check.png";
import uncheck from "../assets/uncheck.png";
import cogoToast from "cogo-toast";

export default function Accordian({
  title,
  content,
  setMultiLimit,
  MultiLimit,
  QuesID,
  ID,
  Data,
  setData,
  MultiLimitSub,
  setMultiLimitSub,
  studentDetails,
}) {
  const [isOpened, setOpened] = useState(false);
  const [height, setHeight] = useState("0px");
  const contentElement = useRef(null);
  const [tileQues, settitleQues] = useState([]);
  const [contentQues, setContentQues] = useState([]);
  const [subgoal, setSubgoal] = useState(0);

  useEffect(() => {
    setData(Data);
  }, [MultiLimit, MultiLimitSub]);

  const HandleOpening = (index) => {
    setOpened(!isOpened);
    setSubgoal(index);
  };

  const handleCheckSupergoal = (index) => {
    setMultiLimitSub([]);
  };

  const handleCheckSubGoal = (i) => {
    //this is for math section
    if (ID === 1 && QuesID === 5) {
      var getallchecked = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true).length;

      var currentvalue =
        Data[ID].questions[QuesID].goalQues[subgoal].text[i].check;

      var totalsubchecked = 0;
      //Data[ID].questions[QuesID].goalQues.forEach((element) => {
      var filteredvalues = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true);
      totalsubchecked += filteredvalues.length;
      ///});

      if (currentvalue === true) {
        Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          check: false,
        };
        if (getallchecked == 1) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = false;
        }
        setData(Data);
      } else {
        if (getallchecked == 0) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = true;
          setData(Data);
        }
        var totalsuperchecked = 0;
        var totalsupergoalselected = Data[ID].questions[QuesID].goalQues.filter(
          (x) => x.check == true
        );
        totalsuperchecked = totalsupergoalselected.length;

        if (totalsuperchecked < 4) {
          // Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          //   ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          //   check: true,
          // };
          // setData(Data);
          if (totalsubchecked < 3) {
            Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
              ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
              check: true,
            };
            setData(Data);
          } else {
            if (getallchecked == 0) {
              Data[ID].questions[QuesID].goalQues[subgoal].check = false;
              setData(Data);
            }
            cogoToast.error("A maximum of 3 subgoals are allowed");
          }
        } else {
          if (getallchecked == 0) {
            Data[ID].questions[QuesID].goalQues[subgoal].check = false;
            setData(Data);
          }

          cogoToast.error("Maximum 3 smart goals allowed");
        }
      }
    }
    if (ID === 1 && QuesID === 6) {
      var getallchecked = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true).length;

      var currentvalue =
        Data[ID].questions[QuesID].goalQues[subgoal].text[i].check;

      var totalsubchecked = 0;
      Data[ID].questions[QuesID].goalQues.forEach((element) => {
        var filteredvalues = element.text.filter((x) => x.check == true);
        totalsubchecked += filteredvalues.length;
      });

      if (currentvalue === true) {
        Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          check: false,
        };
        if (getallchecked == 1) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = false;
        }
        setData(Data);
      } else {
        if (getallchecked == 0) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = true;
          setData(Data);
        }
        var totalsuperchecked = 0;
        var totalsupergoalselected = Data[ID].questions[QuesID].goalQues.filter(
          (x) => x.check == true
        );
        totalsuperchecked = totalsupergoalselected.length;
        if (totalsuperchecked < 4) {
          // Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          //   ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          //   check: true,
          // };
          // setData(Data);
          if (totalsubchecked < 5) {
            Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
              ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
              check: true,
            };
            setData(Data);
          } else {
            if (getallchecked == 0) {
              Data[ID].questions[QuesID].goalQues[subgoal].check = false;
              setData(Data);
            }
            cogoToast.error("A maximum of 5 subgoals are allowed");
          }
        } else {
          if (getallchecked == 0) {
            Data[ID].questions[QuesID].goalQues[subgoal].check = false;
            setData(Data);
          }
          cogoToast.error("Maximum 3 smart goals allowed");
          Data[ID].questions[QuesID].limit = true;
        }
      }

      var totalsubcheckedlimit = 0;
      Data[ID].questions[QuesID].goalQues.forEach((element) => {
        let filteredvalues = element.text.filter((x) => x.check == true);
        totalsubcheckedlimit += filteredvalues.length;
      });

      if (totalsubcheckedlimit == 5) {
        console.log(totalsubchecked, "<----totalsubchecked");

        Data[ID].questions[QuesID].limit = true;
      } else {
        console.log(totalsubchecked, "<----totalsubchecked");

        Data[ID].questions[QuesID].limit = false;
      }
    }
    if (ID === 2 && QuesID === 5) {
      var getallchecked = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true).length;

      var currentvalue =
        Data[ID].questions[QuesID].goalQues[subgoal].text[i].check;

      var totalsubchecked = 0;
      //Data[ID].questions[QuesID].goalQues.forEach((element) => {
      var filteredvalues = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true);
      totalsubchecked += filteredvalues.length;
      //});

      if (currentvalue === true) {
        Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          check: false,
        };
        if (getallchecked == 1) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = false;
        }
        Data[ID].questions[QuesID].limit = false;
        setData(Data);
      } else {
        if (getallchecked == 0) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = true;
          setData(Data);
        }
        var totalsuperchecked = 0;
        var totalsupergoalselected = Data[ID].questions[QuesID].goalQues.filter(
          (x) => x.check == true
        );
        totalsuperchecked = totalsupergoalselected.length;
        if (totalsuperchecked < 4) {
          // Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          //   ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          //   check: true,
          // };
          // setData(Data);
          if (totalsubchecked < 3) {
            Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
              ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
              check: true,
            };
            Data[ID].questions[QuesID].limit = false;
            setData(Data);
          } else {
            if (getallchecked == 0) {
              Data[ID].questions[QuesID].goalQues[subgoal].check = false;
              setData(Data);
            }
            Data[ID].questions[QuesID].limit = true;
            cogoToast.error("A maximum of 3 subgoals are allowed");

          }
        } else {
          if (getallchecked == 0) {
            Data[ID].questions[QuesID].goalQues[subgoal].check = false;
            setData(Data);
          }
          Data[ID].questions[QuesID].limit = true;
          cogoToast.error("Maximum 3 smart goals allowed");
        }
        console.log(getallchecked, "<---getallchecked");

        var totalSelected = 0;
        Data[ID].questions[QuesID].goalQues
          .filter((x) => x.check === true)
          .forEach((val, index) => {
            totalSelected += val.text.filter((y) => y.check === true).length;
          });

        console.log(totalSelected, "<----totalsubchecked");
        if (totalSelected == 9) {
          Data[ID].questions[QuesID].limit = true;
        } else {
          Data[ID].questions[QuesID].limit = false;
        }
        setData(Data);
      }
    }
    if (ID === 2 && QuesID === 6) {
      var getallchecked = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true).length;

      var currentvalue =
        Data[ID].questions[QuesID].goalQues[subgoal].text[i].check;

      var totalsubchecked = 0;
      //Data[ID].questions[QuesID].goalQues.forEach((element) => {
      var filteredvalues = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true);
      totalsubchecked += filteredvalues.length;
      //});

      if (currentvalue === true) {
        Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          check: false,
        };
        if (getallchecked == 1) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = false;
        }
        setData(Data);
      } else {
        if (getallchecked == 0) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = true;
          setData(Data);
        }
        var totalsuperchecked = 0;
        var totalsupergoalselected = Data[ID].questions[QuesID].goalQues.filter(
          (x) => x.check == true
        );
        totalsuperchecked = totalsupergoalselected.length;
        if (totalsuperchecked < 4) {
          // Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          //   ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          //   check: true,
          // };
          // setData(Data);
          if (totalsubchecked < 3) {
            Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
              ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
              check: true,
            };
            setData(Data);
          } else {
            if (getallchecked == 0) {
              Data[ID].questions[QuesID].goalQues[subgoal].check = false;
              setData(Data);
            }
            Data[ID].questions[QuesID].limit = true;
            cogoToast.error("A maximum of 3 subgoals are allowed");
          }
        } else {
          if (getallchecked == 0) {
            Data[ID].questions[QuesID].goalQues[subgoal].check = false;
            setData(Data);
          }

          cogoToast.error("Maximum 3 smart goals allowed");
        }
      }

      var totalSelected = 0;
      Data[ID].questions[QuesID].goalQues
        .filter((x) => x.check === true)
        .forEach((val, index) => {
          totalSelected += val.text.filter((y) => y.check === true).length;
        });

      console.log(totalSelected, "<----totalsubchecked");
      if (totalSelected == 9) {
        Data[ID].questions[QuesID].limit = true;
      } else {
        Data[ID].questions[QuesID].limit = false;
      }
    }
    if (ID === 3 && QuesID === 4) {
      var getallchecked = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true).length;

      var currentvalue =
        Data[ID].questions[QuesID].goalQues[subgoal].text[i].check;

      var totalsubchecked = 0;
      Data[ID].questions[QuesID].goalQues.forEach((element) => {
        var filteredvalues = element.text.filter((x) => x.check == true);
        totalsubchecked += filteredvalues.length;
      });

      if (currentvalue === true) {
        Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          check: false,
        };
        if (getallchecked == 1) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = false;
        }
        setData(Data);
      } else {
        if (getallchecked == 0) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = true;
          setData(Data);
        }
        var totalsuperchecked = 0;
        var totalsupergoalselected = Data[ID].questions[QuesID].goalQues.filter(
          (x) => x.check == true
        );
        totalsuperchecked = totalsupergoalselected.length;
        if (totalsuperchecked < 4) {
          Data[ID].questions[QuesID].limit = false;
          // Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          //   ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          //   check: true,
          // };
          // setData(Data);

          if (getallchecked === 1) {
            cogoToast.error("Maximum 1 subgoal allowed");
          } else if (totalsubchecked < 6) {
            Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
              ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
              check: true,
            };
            setData(Data);
          } else {
            if (getallchecked == 0) {
              Data[ID].questions[QuesID].goalQues[subgoal].check = false;
              setData(Data);
            }
            Data[ID].questions[QuesID].limit = true;
            cogoToast.error("Maximum 5 subgoals allowed");
          }
        } else {
          if (getallchecked == 0) {
            Data[ID].questions[QuesID].goalQues[subgoal].check = false;
            setData(Data);
          }
          Data[ID].questions[QuesID].limit = true;
          cogoToast.error("Maximum 3 smart goals allowed");
        }
      }

      var totalSelected = 0;
      Data[ID].questions[QuesID].goalQues
        .filter((x) => x.check === true)
        .forEach((val, index) => {
          totalSelected += val.text.filter((y) => y.check === true).length;
        });

      console.log(totalSelected, "<----totalsubchecked");
    }
    if (ID === 3 && QuesID === 5) {
      var getallchecked = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true).length;

      var currentvalue =
        Data[ID].questions[QuesID].goalQues[subgoal].text[i].check;

      var totalsubchecked = 0;
      Data[ID].questions[QuesID].goalQues.forEach((element) => {
        var filteredvalues = element.text.filter((x) => x.check == true);
        totalsubchecked += filteredvalues.length;
      });

      if (currentvalue === true) {
        Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          check: false,
        };
        if (getallchecked == 1) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = false;
        }
        setData(Data);
      } else {
        if (getallchecked == 0) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = true;
          setData(Data);
        }
        var totalsuperchecked = 0;
        var totalsupergoalselected = Data[ID].questions[QuesID].goalQues.filter(
          (x) => x.check == true
        );
        totalsuperchecked = totalsupergoalselected.length;
        if (totalsuperchecked < 4) {
          Data[ID].questions[QuesID].limit = false;
          // Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          //   ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          //   check: true,
          // };
          // setData(Data);
          if (getallchecked === 1) {
            cogoToast.error("Maximum 1 subgoal allowed");
          } else if (totalsubchecked < 6) {
            Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
              ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
              check: true,
            };
            setData(Data);
          } else {
            if (getallchecked == 0) {
              Data[ID].questions[QuesID].goalQues[subgoal].check = false;
              setData(Data);
            }
            Data[ID].questions[QuesID].limit = true;
            cogoToast.error("Maximum 5 subgoals allowed");
          }
        } else {
          Data[ID].questions[QuesID].limit = true;
          if (getallchecked == 0) {
            Data[ID].questions[QuesID].goalQues[subgoal].check = false;
            setData(Data);
          }
          cogoToast.error("Maximum 3 smart goals allowed");
        }
      }
      var totalSelected = 0;
      Data[ID].questions[QuesID].goalQues
        .filter((x) => x.check === true)
        .forEach((val, index) => {
          totalSelected += val.text.filter((y) => y.check === true).length;
        });
    }

    if (ID === 5 && QuesID === 2) {
      var getallchecked = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true).length;

      var currentvalue =
        Data[ID].questions[QuesID].goalQues[subgoal].text[i].check;

      var totalsubchecked = 0;
      //Data[ID].questions[QuesID].goalQues.forEach((element) => {
      var filteredvalues = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true);
      totalsubchecked += filteredvalues.length;
      //});

      if (currentvalue === true) {
        Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          check: false,
        };
        if (getallchecked == 1) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = false;
        }
        setData(Data);
      } else {
        if (getallchecked == 0) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = true;
          setData(Data);
        }
        var totalsuperchecked = 0;
        var totalsupergoalselected = Data[ID].questions[QuesID].goalQues.filter(
          (x) => x.check == true
        );
        totalsuperchecked = totalsupergoalselected.length;
        if (totalsuperchecked < 4) {
          // Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          //   ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          //   check: true,
          // };
          // setData(Data);
          if (totalsubchecked < 1) {
            Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
              ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
              check: true,
            };
            setData(Data);
          } else {
            if (getallchecked == 0) {
              Data[ID].questions[QuesID].goalQues[subgoal].check = false;
              setData(Data);
            }
            // Data[ID].questions[QuesID].limit = true;
            cogoToast.error("Only 1 answer for each section is allowed");
          }
        } else {
          if (getallchecked == 0) {
            Data[ID].questions[QuesID].goalQues[subgoal].check = false;
            setData(Data);
          }
          cogoToast.error("Maximum 3 smart goals allowed");
        }
      }
    }
    if (ID === 5 && QuesID === 3) {
      var getallchecked = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true).length;

      var currentvalue =
        Data[ID].questions[QuesID].goalQues[subgoal].text[i].check;

      var totalsubchecked = 0;
      //Data[ID].questions[QuesID].goalQues.forEach((element) => {
      var filteredvalues = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true);
      totalsubchecked += filteredvalues.length;
      //});

      if (currentvalue === true) {
        Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          check: false,
        };
        if (getallchecked == 1) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = false;
        }
        setData(Data);
      } else {
        if (getallchecked == 0) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = true;
          setData(Data);
        }
        var totalsuperchecked = 0;
        var totalsupergoalselected = Data[ID].questions[QuesID].goalQues.filter(
          (x) => x.check == true
        );
        totalsuperchecked = totalsupergoalselected.length;
        if (totalsuperchecked < 4) {
          // Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          //   ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          //   check: true,
          // };
          // setData(Data);
          if (totalsubchecked < 1) {
            Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
              ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
              check: true,
            };
            setData(Data);
          } else {
            if (getallchecked == 0) {
              Data[ID].questions[QuesID].goalQues[subgoal].check = false;
              setData(Data);
            }
            // Data[ID].questions[QuesID].limit = true;
            cogoToast.error("Only 1 answer for each section is allowed");
          }
        } else {
          if (getallchecked == 0) {
            Data[ID].questions[QuesID].goalQues[subgoal].check = false;
            setData(Data);
          }
          cogoToast.error("1 answer for each section is required");
        }
      }
    }
    if (ID === 5 && QuesID === 4) {
      var getallchecked = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true).length;

      var currentvalue =
        Data[ID].questions[QuesID].goalQues[subgoal].text[i].check;

      var totalsubchecked = 0;
      //Data[ID].questions[QuesID].goalQues.forEach((element) => {
      var filteredvalues = Data[ID].questions[QuesID].goalQues[
        subgoal
      ].text.filter((x) => x.check == true);
      totalsubchecked += filteredvalues.length;
      //});

      if (currentvalue === true) {
        Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          check: false,
        };
        if (getallchecked == 1) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = false;
        }
        setData(Data);
      } else {
        if (getallchecked == 0) {
          Data[ID].questions[QuesID].goalQues[subgoal].check = true;
          setData(Data);
        }
        var totalsuperchecked = 0;
        var totalsupergoalselected = Data[ID].questions[QuesID].goalQues.filter(
          (x) => x.check == true
        );
        totalsuperchecked = totalsupergoalselected.length;
        if (totalsuperchecked < 4) {
          // Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
          //   ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
          //   check: true,
          // };
          // setData(Data);
          if (totalsubchecked < 10) {
            Data[ID].questions[QuesID].goalQues[subgoal].text[i] = {
              ...Data[ID].questions[QuesID].goalQues[subgoal].text[i],
              check: true,
            };
            setData(Data);
          } else {
            if (getallchecked == 0) {
              Data[ID].questions[QuesID].goalQues[subgoal].check = false;
              setData(Data);
            }
            Data[ID].questions[QuesID].limit = true;
            cogoToast.error("Maximum 9 subgoals allowed");
          }
        } else {
          if (getallchecked == 0) {
            Data[ID].questions[QuesID].goalQues[subgoal].check = false;
            setData(Data);
          }
          cogoToast.error("Maximum 3 smart goals allowed");
        }
      }
    }
  };
  useEffect(() => {
    settitleQues(title);

    setContentQues(content);
  }, [tileQues, contentQues, MultiLimitSub, MultiLimit]);
  return (
    <>
      {title?.goalQues?.map((record, index) => {
        const recordsChecked = record.text.filter(el => el.check);
        const goalsChecked = title?.goalQues.filter(el => el.check);
        console.log(goalsChecked);
        const recordsCheckedLength = recordsChecked.length;
        const sublimit = Data[ID].questions[QuesID].sublimit;
        const goalLimit = Data[ID].questions[QuesID].goalLimit;
        console.log(goalLimit)
        const shouldBDisable = sublimit === recordsCheckedLength;
        const shouldGoalsDisabled = goalsChecked.length === goalLimit && !record.check
        console.log(shouldGoalsDisabled);

        return ((
            <div className="selectWrapper">
              <div onClick={() => HandleOpening(index)} className="selectBorder">
                <div className={"outboxes flex justify-between items-center"}>
                  <li className="flex flex-row">
                    {record.check === true ? (
                        <div
                            className="checkBox"
                            onClick={() => handleCheckSupergoal(index)}
                        ></div>
                    ) : (
                        // <img
                        //   src={check}
                        //   className={"h-8 mr-2"}
                        //   onClick={() => handleCheckSupergoal(index)}
                        // />
                        <div
                            className={
                              (Data[ID].questions[QuesID].limit === true || shouldBDisable || shouldGoalsDisabled)
                                  ? "Disable"
                                  : "UncheckBox"
                            }
                            onClick={() => handleCheckSupergoal(index)}
                        ></div>

                        // <img
                        //   src={uncheck}
                        //   className={"h-8 mr-2"}
                        //   onClick={() => handleCheckSupergoal(index)}
                        // />
                    )}

                    {record.value}
                  </li>
                  {/* {isOpened ? <ChevronOpen /> : <ChevronClosed />} */}
                </div>
                <>
                  {subgoal === index &&
                      record.text?.map((rec, i) => (
                          <div
                              ref={contentElement}
                              // style=  {{ height: height }}
                              className="innerBoxes overflow-hidden transition-all duration-200 items-center"
                          >
                            {/* <label class="inline-flex items-center p-2"></label> */}

                            <li className="flex flex-row areeb">
                              {rec.check === true ? (
                                  <div
                                      className="checkBox"
                                      onClick={() => handleCheckSubGoal(i)}
                                  ></div>
                              ) : (
                                  // <img
                                  //   src={check}
                                  //   className={"h-8 mr-2"}
                                  //   onClick={() => handleCheckSubGoal(i)}
                                  // />
                                  <div
                                      className={
                                        (Data[ID].questions[QuesID].limit === true || shouldBDisable || shouldGoalsDisabled)
                                            ? "Disable"
                                            : "UncheckBox"
                                      }
                                      onClick={() => handleCheckSubGoal(i)}
                                  ></div>
                                  // <img
                                  //   src={uncheck}
                                  //   className={"h-8 mr-2"}
                                  //   onClick={() => handleCheckSubGoal(i)}
                                  // />
                              )}
                              <p>
                                {studentDetails.Gender === "Male"
                                    ? rec.text.includes("P1")
                                        ? rec.text.replace("P1", "he")
                                        : rec.text.includes("P2")
                                            ? rec.text.replace("P2", "him")
                                            : rec.text.includes("P3")
                                                ? rec.text.replace("P3", "his")
                                                : rec.text
                                    : rec.text.includes("P1")
                                        ? rec.text.replace("P1", "she")
                                        : rec.text.includes("P2")
                                            ? rec.text.replace("P2", "her")
                                            : rec.text.includes("P3")
                                                ? rec.text.replace("P3", "her")
                                                : rec.text}
                                {/* {rec.text} */}
                              </p>
                            </li>
                          </div>
                      ))}
                </>
              </div>
            </div>
        ))
      })}
    </>
  );
}
