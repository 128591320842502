export let initialData = [
  {
    id: 1,
    title: "Background",
    questions: [
      {
        isUpdated: false,
        questionID: 1,
        subTitle: "Areas of Struggle",
        description: "Select 2 to 3",
        question: `Which areas does name struggle with most?`,
        select: "Multi",
        min: 2,
        max: 3,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "attention and focus skills",
            check: false,
            show: [0, 1, 2, 3, 4],
          },
          {
            id: 2,
            value: "long term memory",
            check: false,
            show: [0, 1, 2, 3, 4],
          },
          {
            id: 3,
            value: "short term memory",
            check: false,
            show: [0, 1, 2, 3, 4],
          },
          {
            id: 4,
            value: "working memory",
            check: false,
            show: [0, 1, 2, 3, 4],
          },
          {
            id: 5,
            value: "emotional regulation",
            check: false,
            show: [0, 1, 2, 3, 4],
          },
          {
            id: 6,
            value: "auditory processing",
            check: false,
            show: [0, 1, 2, 3, 4],
          },
          { id: 7, value: "language skills", check: false, show: [0, 1, 2] },
          { id: 8, value: "reading skills", check: false, show: [0, 1, 2] },
          {
            id: 9,
            value: "phonemic awareness",
            check: false,
            show: [0, 1, 2],
          },
          {
            id: 10,
            value: "executive function skills",
            check: false,
            show: [3, 4],
          },
          {
            id: 11,
            value: "visual-spatial awareness",
            check: false,
            show: [3, 4],
          },
          { id: 12, value: "math skills", check: false, show: [3, 4] },
        ],
      },
      {
        isUpdated: false,
        questionID: 2,
        subTitle: "Functioning Rate",
        description: "Select 1",
        question: "Rate name's level of functioning in these areas",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "very poor",
            check: false,
          },
          {
            id: 2,
            value: "poor",
            check: false,
          },
          {
            id: 3,
            value: "below average",
            check: false,
          },
          {
            id: 4,
            value: "slightly below average",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 3,
        subTitle: "Academic Domains",
        description: "Select 1 to 2",
        question: "Which academic domains does name struggle with?",
        select: "Multi",
        min: 1,
        max: 2,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "decoding",
            check: false,
            isHidden: false,
          },
          {
            id: 2,
            value: "reading fluency",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "reading comprehension",
            check: false,
            isHidden: false,
          },
          {
            id: 4,
            value: "math computation",
            check: false,
            isHidden: false,
          },
          {
            id: 5,
            value: "word problems in math",
            check: false,
            isHidden: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 4,
        subTitle: "Interests",
        question: "What are name's interests?",
        description: "Select 2 to 3",
        select: "Multi",
        min: 2,
        max: 3,
        limit: false,
        answered: false,
        options: [
          { id: 1, value: "drawing", check: false },
          { id: 2, value: "coloring", check: false },
          { id: 3, value: "singing", check: false },
          { id: 4, value: "dancing", check: false },
          { id: 5, value: "playing with dolls", check: false },
          { id: 6, value: "playing with cars", check: false },
          { id: 7, value: "playing games", check: false },
          { id: 8, value: "playing with stickers", check: false },
          { id: 9, value: "sports", check: false },
          { id: 10, value: "gymnastics", check: false },
          { id: 11, value: "tickets", check: false },
          { id: 12, value: "computers", check: false },
          { id: 13, value: "technology", check: false },
          { id: 14, value: "playing with animals", check: false },
        ],
      },
      {
        isUpdated: false,
        questionID: 5,
        subTitle: "Incorporate Interests",
        description: "Select 1",
        question: "Why do you incorporate these interests into your sessions?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "to keep name focused on the lesson",
            check: false,
          },
          {
            id: 2,
            value: "to improve name's willingness to learn",
            check: false,
          },
          {
            id: 3,
            value: "to make name's learning experience pleasurable",
            check: false,
          },
          {
            id: 4,
            value: "to make name's learning more exciting for name",
            check: false,
          },
          {
            id: 5,
            value: "to increase name's attention while learning",
            check: false,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Decoding",
    questions: [
      {
        isUpdated: false,
        questionID: 6,
        subTitle: "Decoding Rate",
        description: "Select 1",
        question: "How would you rate name's performance in decoding?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "very poor performance",
            check: false,
          },
          {
            id: 2,
            value: "poor performance",
            check: false,
          },
          { id: 3, value: "below average performance", check: false },
        ],
      },
      {
        isUpdated: false,
        questionID: 7,
        subTitle: "Decoding Areas",
        description: "Select 1",
        question: "How many areas of decoding does name struggle with?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "most",
            check: false,
          },
          {
            id: 2,
            value: "many",
            check: false,
          },
          { id: 3, value: "some", check: false },
          { id: 4, value: "few", check: false },
        ],
      },
      {
        isUpdated: false,
        questionID: 8,
        subTitle: "Grade Level",
        description: "Select 1",
        question: "Rate name's grade level in decoding",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "pre-K",
            check: false,
            level: 1,
            isHidden: false,
          },
          {
            id: 2,
            value: "lower kindergarten",
            check: false,
            level: 1,
            isHidden: false,
          },
          {
            id: 3,
            value: "mid-kindergarten",
            check: false,
            level: 1,
            isHidden: false,
          },
          {
            id: 4,
            value: "upper-kindergarten",
            check: false,
            level: 1,
            isHidden: false,
          },
          {
            id: 4,
            value: "beginning first grade",
            check: false,
            level: 2,
            isHidden: false,
          },
          {
            id: 4,
            value: "mid-first grade",
            check: false,
            level: 2,
            isHidden: false,
          },
          {
            id: 4,
            value: "upper-first grade",
            check: false,
            level: 2,
            isHidden: false,
          },
          {
            id: 4,
            value: "beginning-second grade",
            check: false,
            level: 3,
            isHidden: false,
          },
          {
            id: 4,
            value: "mid-second grade",
            check: false,
            level: 3,
            isHidden: false,
          },
          {
            id: 4,
            value: "upper-second grade",
            check: false,
            level: 3,
            isHidden: false,
          },
          {
            id: 4,
            value: "beginning third grade",
            check: false,
            level: 4,
            isHidden: false,
          },
          {
            id: 4,
            value: "mid-third grade",
            check: false,
            level: 4,
            isHidden: false,
          },
          {
            id: 4,
            value: "upper-third grade",
            check: false,
            level: 4,
            isHidden: false,
          },
          {
            id: 4,
            value: "beginning fourth grade",
            check: false,
            level: 5,
            isHidden: false,
          },
          {
            id: 4,
            value: "mid-fourth grade",
            check: false,
            level: 5,
            isHidden: false,
          },
          {
            id: 4,
            value: "upper-fourth grade",
            check: false,
            level: 5,
            isHidden: false,
          },
          {
            id: 4,
            value: "beginning fifth grade",
            check: false,
            level: 6,
            isHidden: false,
          },
          {
            id: 4,
            value: "mid-fifth grade",
            check: false,
            level: 6,
            isHidden: false,
          },
          {
            id: 4,
            value: "upper-fifth grade",
            check: false,
            level: 6,
            isHidden: false,
          },
          {
            id: 4,
            value: "beginning sixth grade",
            check: false,
            level: 7,
            isHidden: false,
          },
          {
            id: 4,
            value: "mid-sixth grade",
            check: false,
            level: 7,
            isHidden: false,
          },
          {
            id: 4,
            value: "upper-sixth grade",
            check: false,
            level: 7,
            isHidden: false,
          },
          {
            id: 4,
            value: "beginning seventh grade",
            check: false,
            level: 8,
            isHidden: false,
          },
          {
            id: 4,
            value: "mid-seventh grade ",
            check: false,
            level: 8,
            isHidden: false,
          },
          {
            id: 4,
            value: "upper seventh grade",
            check: false,
            level: 8,
            isHidden: false,
          },
          {
            id: 4,
            value: "beginning eighth grade",
            check: false,
            level: 9,
            isHidden: false,
          },
          {
            id: 4,
            value: "mid-eighth grade",
            check: false,
            level: 9,
            isHidden: false,
          },
          {
            id: 4,
            value: "upper-eighth grade",
            check: false,
            level: 9,
            isHidden: false,
          },
        ],
      },
      // {
      // questionID : 8,
      //   subTitle: "Instruction Type",
      //   description: "Minimum 1",
      //   question: "What type of instruction do you utilize during sessions?",
      //   select: "single",
      //   answered: false,
      //   isRandom: true,
      //   options: [
      //     { id: 1, value: "Explicit instruction", check: false },
      //     { id: 2, value: "Individualized instruction", check: false },
      //     { id: 3, value: "Concrete instruction", check: false },
      //     { id: 4, value: "Step-by-step instruction", check: false },
      //     { id: 5, value: "Scaffolded instruction", check: true },
      //     { id: 6, value: "Skilled intervention", check: false },
      //     { id: 7, value: "1:1 intervention", check: false },
      //   ],
      // },
      {
        isUpdated: false,
        questionID: 9,
        subTitle: "Reading Methodology",
        description: "Select 1 to 3",
        question: "Which types of methodology do you use during reading instruction?",
        select: "Multi",
        answered: false,
        min: 1,
        max: 3,
        limit: false,
        options: [
          { id: 1, value: "ORTON GILLIINGHAM Methodology", check: false },
          { id: 2, value: "WILSON Methodology", check: false },
          { id: 3, value: "LIPS Methodology", check: false },
          { id: 4, value: "Visual Aids", check: false },
          { id: 5, value: "Tactile Manipulatives", check: false },
          { id: 6, value: "Concrete Manipulatives", check: false },
          { id: 7, value: "Magnet Cards", check: false },
          { id: 8, value: "Phonics Cubes", check: false },
        ],
      },
      {
        isUpdated: false,
        questionID: 10,
        subTitle: "Descriptive Rating Chart",
        description: "Select 1",
        question: "How would you rate name's progress in decoding?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          { id: 1, text: "very little", value: "very little", check: false },
          { id: 2, text: "minimal", value: "minimal", check: false },
          { id: 3, text: "some", value: "some", check: false },
          { id: 4, text: "significant", value: "significant", check: false },
          { id: 5, text: "major", value: "major", check: false },
        ],
      },
      {
        isUpdated: false,
        limit: false,
        questionID: 11,
        subTitle: "Decoding Goals",
        description: "Select 1-3 smart goals and up to 3 goals for the smart goal",
        question: "What are your decoding goals for the year?",
        select: "Accordian",
        dependQuestion: 2,
        answered: false,
        sublimit: 3,
        goalLimit: 3,
        goalQues: [],
        suberGoals: [
          {
            title: "Demonstrate phonetic awareness skills",
            level: 1,
            subGoals: [
              "following words from left to right, top to bottom, and page by page",
              "recognizing and naming all upper-case and lower-case letters of the alphabet",
            ],
          },
          {
            title: "Demonstrate phonemic awareness skills",
            level: 1,
            subGoals: [
              "recognizing and producing rhyming words",
              "identifying the initial and final sounds of words",
              "blending and segmenting the sounds in words",
              "adding and deleting sounds of words",
              "substituting sounds in words",
            ],
          },
          {
            title: "Decode",
            level: 1,
            subGoals: [
              "demonstrating knowledge of sound-symbol relationships.",
              "identifying vowel sounds",
              "reading grade-level sight word",
            ],
          },
          {
            title: "Demonstrate phonemic awareness skills",
            level: 2,
            subGoals: [
              "distinguishing long from short vowel sounds.",
              "blending words sounds (including consonant blends).",
              "segmenting word sounds.",
            ],
          },
          {
            title: "Decode",
            level: 2,
            subGoals: [
              "decoding regularly spelled one-syllable words",
              "decoding words in the CVC format",
              "decoding words with consonant blends",
              "decoding words with consonant digraphs",
              "decoding words in the CVCe format",
              "decoding words with vowel teams",
              "decoding words with diphthongs",
              "decoding words with r-controlled vowels",
              "decoding two-syllable words",
              "reading words with inflectional endings (such as s,ed,ing)",
              "recognizing and reading grade-appropriate irregularly spelled words",
            ],
          },
          {
            title: "Decode",
            level: 3,
            subGoals: [
              "decoding regularly spelled two-syllable words",
              "decoding words with common prefixes and suffixes",
              "recognizing and reading grade-appropriate irregularly spelled words.",
            ],
          },
          {
            title: "Decode",
            level: 4,
            subGoals: [
              "decoding words with common Latin suffixes",
              "decoding multisyllabic words",
              "reading grade-appropriate irregularly spelled words",
              "reading with sufficient accuracy and fluency to support comprehension",
            ],
          },
          {
            title: "Read text with purpose and understanding",
            level: 4,
            subGoals: [
              "reading with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct as necessary",
            ],
          },
          {
            title: "Decode",
            level: 5,
            subGoals: ["using combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology"],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 5,
            subGoals: [
              "reading text with purpose and understanding",
              "reading with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct as necessary",
            ],
          },
          {
            title: "Decode",
            level: 6,
            subGoals: [
              "decoding complex multisyllabic words",
              "reading irregularly spelled words"
            ],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 6,
            subGoals: [
              "reading text with purpose and understanding",
              "reading with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct as necessary",
            ],
          },
          {
            title: "Know and apply phonics and word analysis skills in decoding words",
            level: 7,
            subGoals: [
              "using combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology (e.g., roots and affixes) to read accurately unfamiliar multisyllabic words in context and out of context",
            ],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 7,
            subGoals: [
              "reading text with purpose and understanding",
              "reading prose and poetry orally with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct word recognition and understanding, rereading as necessary",
            ],
          },
          {
            title: "Know and apply phonics and word analysis skills in decoding words",
            level: 8,
            subGoals: [
              "using combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology (e.g., roots and affixes) to read accurately unfamiliar multisyllabic words in context and out of context",
            ],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 8,
            subGoals: [
              "reading text with purpose and understanding",
              "reading prose and poetry orally with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct word recognition and understanding, rereading as necessary",
            ],
          },
          {
            title: "Know and apply phonics and word analysis skills in decoding words",
            level: 9,
            subGoals: [
              "using combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology (e.g., roots and affixes) to read accurately unfamiliar multisyllabic words in context and out of context",
            ],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 9,
            subGoals: [
              "reading text with purpose and understanding",
              "reading prose and poetry orally with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct word recognition and understanding, rereading as necessary",
            ],
          },
        ],
      },
      {
        isUpdated: false,
        limit: false,
        questionID: 12,
        subTitle: "Struggles",
        description: "Select 3 to 5 subgoals",
        question: "Which areas in decoding does name still struggle with?",
        select: "Accordian",
        dependQuestion: 2,
        answered: false,
        goalQues: [],
        suberGoals: [
          {
            title: "Demonstrate phonetic awareness skills",
            level: 1,
            subGoals: [
              "following words from left to right, top to bottom, and page by page",
              "recognizing and naming all upper-case and lower-case letters of the alphabet",
            ],
          },
          {
            title: "Demonstrate phonemic awareness skills",
            level: 1,
            subGoals: [
              "recognizing and producing rhyming words",
              "identifying the initial and final sounds of words",
              "blending and segmenting the sounds in words",
              "adding and deleting sounds of words",
              "substituting sounds in words",
            ],
          },
          {
            title: "Decode",
            level: 1,
            subGoals: [
              "demonstrating knowledge of sound-symbol relationships.",
              "identifying vowel sounds",
              "reading grade-level sight word",
            ],
          },
          {
            title: "Demonstrate phonemic awareness skills",
            level: 2,
            subGoals: [
              "distinguishing long from short vowel sounds.",
              "blending words sounds (including consonant blends).",
              "segmenting word sounds.",
            ],
          },
          {
            title: "Decode",
            level: 2,
            subGoals: [
              "decoding regularly spelled one-syllable words",
              "decoding words in the CVC format",
              "decoding words with consonant blends",
              "decoding words with consonant digraphs",
              "decoding words in the CVCe format",
              "decoding words with vowel teams",
              "decoding words with diphthongs",
              "decoding words with r-controlled vowels",
              "decoding two-syllable words",
              "reading words with inflectional endings (such as s,ed,ing)",
              "recognizing and reading grade-appropriate irregularly spelled words",
            ],
          },
          {
            title: "Decode",
            level: 3,
            subGoals: [
              "decoding regularly spelled two-syllable words",
              "decoding words with common prefixes and suffixes",
              "recognizing and reading grade-appropriate irregularly spelled words.",
            ],
          },
          {
            title: "Decode",
            level: 4,
            subGoals: [
              "decoding words with common Latin suffixes",
              "decoding multisyllabic words",
              "reading grade-appropriate irregularly spelled words",
              "reading with sufficient accuracy and fluency to support comprehension",
            ],
          },
          {
            title: "Read text with purpose and understanding",
            level: 4,
            subGoals: [
              "reading with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct as necessary",
            ],
          },
          {
            title: "Decode",
            level: 5,
            subGoals: ["using combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology"],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 5,
            subGoals: [
              "reading text with purpose and understanding",
              "reading with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct as necessary",
            ],
          },
          {
            title: "Decode",
            level: 6,
            subGoals: [
              "decoding complex multisyllabic words",
              "reading irregularly spelled words"
            ],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 6,
            subGoals: [
              "reading text with purpose and understanding",
              "reading with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct as necessary",
            ],
          },
          {
            title: "Know and apply phonics and word analysis skills in decoding words",
            level: 7,
            subGoals: [
              "using combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology (e.g., roots and affixes) to read accurately unfamiliar multisyllabic words in context and out of context",
            ],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 7,
            subGoals: [
              "reading text with purpose and understanding",
              "reading prose and poetry orally with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct word recognition and understanding, rereading as necessary",
            ],
          },
          {
            title: "Know and apply phonics and word analysis skills in decoding words",
            level: 8,
            subGoals: [
              "using combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology (e.g., roots and affixes) to read accurately unfamiliar multisyllabic words in context and out of context",
            ],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 8,
            subGoals: [
              "reading text with purpose and understanding",
              "reading prose and poetry orally with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct word recognition and understanding, rereading as necessary",
            ],
          },
          {
            title: "Know and apply phonics and word analysis skills in decoding words",
            level: 9,
            subGoals: [
              "using combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology (e.g., roots and affixes) to read accurately unfamiliar multisyllabic words in context and out of context",
            ],
          },
          {
            title: "Read with sufficient accuracy and fluency to support comprehension",
            level: 9,
            subGoals: [
              "reading text with purpose and understanding",
              "reading prose and poetry orally with accuracy, appropriate rate, and expression on successive readings",
              "using context to confirm or self-correct word recognition and understanding, rereading as necessary",
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Reading Comprehension",
    questions: [
      {
        isUpdated: false,
        questionID: 13,
        subTitle: "Deficits",
        description: "Select 1",
        question: "How would you describe name's deficits in reading comprehension?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "severe deficits",
            check: false,
          },
          {
            id: 2,
            value: "some deficits",
            check: false,
          },
          {
            id: 3,
            value: "minor deficits",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 14,
        subTitle: "Range",
        description: "Select 1",
        question: "How would you describe name’s range of difficulties in this domain?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "many areas of reading comprehension",
            check: false,
          },
          {
            id: 2,
            value: "some areas of reading comprehension",
            check: false,
          },
          {
            id: 3,
            value: "few areas of reading comprehension",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 15,
        subTitle: "Grade Level",
        description: "Select 1",
        question: "What is name’s approximate grade level in reading comprehension performance?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "kindergarten",
            check: false,
            isHidden: false,
            level: 1,
          },
          {
            id: 2,
            value: "first grade",
            check: false,
            isHidden: false,
            level: 2,
          },
          {
            id: 3,
            value: "second grade",
            check: false,
            isHidden: false,
            level: 3,
          },
          {
            id: 3,
            value: "third grade",
            check: false,
            isHidden: false,
            level: 4,
          },
          {
            id: 3,
            value: "fourth grade",
            check: false,
            isHidden: false,
            level: 5,
          },
          {
            id: 3,
            value: "fifth grade",
            check: false,
            isHidden: false,
            level: 6,
          },
          {
            id: 3,
            value: "sixth grade",
            check: false,
            isHidden: false,
            level: 7,
          },
          {
            id: 3,
            value: "seventh grade",
            check: false,
            isHidden: false,
            level: 8,
          },
          {
            id: 3,
            value: "eighth grade",
            check: false,
            isHidden: false,
            level: 9,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 16,
        subTitle: "Techniques",
        description: "Select 2 to 3",
        question: "Which techniques do you use during your sessions?",
        select: "Multi",
        answered: false,
        min: 2,
        max: 3,
        limit: false,
        options: [
          {
            id: 1,
            value: "graphic organizers",
            check: false,
          },
          {
            id: 2,
            value: "highlighting techniques",
            check: false,
          },
          {
            id: 3,
            value: "picture cards",
            check: false,
          },
          {
            id: 3,
            value: "visual aids",
            check: false,
          },
          {
            id: 3,
            value: "checklists",
            check: false,
          },
          {
            id: 3,
            value: "color coding techniques",
            check: false,
          },
          {
            id: 3,
            value: "questioning techniques",
            check: false,
          },
          {
            id: 3,
            value: "story maps",
            check: false,
          },
          {
            id: 3,
            value: "read-alouds",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 17,
        subTitle: "Progress",
        description: "Select 1",
        question: "Describe name’s progress in reading comprehension",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "very little",
            check: false,
          },
          {
            id: 2,
            value: "minimal",
            check: false,
          },
          {
            id: 3,
            value: "some",
            check: false,
          },
          {
            id: 3,
            value: "tremendous",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        limit: false,
        questionID: 18,
        subTitle: "Reading Comprehension Goals",
        description: "Select 1-3 smart goals and up to 3 goals for the smart goal",
        question: "What are your reading comprehension goals for the year?",
        select: "Accordian",
        dependQuestion: 2,
        answered: false,
        goalLimit: 3,
        sublimit: 3,
        goalQues: [],
        suberGoals: [
          {
            title: "Identifying key ideas and details",
            level: 1,
            subGoals: [
              "answer ‘wh’ questions based on a picture",
              "identify the main topic and key details based on a picture.",
              "describe connections between individuals or events based on picturest",
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 1,
            subGoals: [
              "identify the front cover, back cover, and title page of a book",
              "name the author and illustrator of a text",
            ],
          },
          {
            title: "demonstrating basic comprehension skills",
            level: 2,
            subGoals: [
              "answer ‘wh’ questions based on tex",
              "identify the main idea of a text.",
              "retell key details of a text",
            ],
          },
          {
            title: "Comprehending text",
            level: 2,
            subGoals: [
              "sequence events of a text",
              "form predictions based on a story",
            ],
          },
          {
            title: "Integrating Knowledge and Ideas",
            level: 2,
            subGoals: [
              "compare and contrast details of a story.",
              "describe the connection between individuals or events, ideas in a text",
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 3,
            subGoals: [
              "answer questions as who, what, where, when, why, and how",
              "identify the main idea",
              "describe connections of elements in a text",
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 3,
            subGoals: [
              "determine the meaning of words and phrases in a text.",
              "identify the main purpose of a text.",
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 3,
            subGoals: [
              "compare and contrast points based on two texts",
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 4,
            subGoals: [
              "answer questions based on text and justify responses",
              "determine the main idea and supporting details",
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 5,
            subGoals: [
              "justify responses when drawing inferences based on text",
              "determine the main idea of a text and provide supporting details",
              "summarize text"
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 5,
            subGoals: [
              "write an essay based on text read"
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 6,
            subGoals: [
              "quote accurately from a text when explaining what the text says explicitly and when drawing inferences from the text",
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 6,
            subGoals: [
              "locate information or answers in a text",
              "integrate information from several texts on the same topic to write or speak about the subject knowledgeably."
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 7,
            subGoals: [
              "cite textual evidence to support analysis",
              "cite textual evidence to support inferences drawn from the text. Determine a central idea of a text and how it is conveyed through particular details",
              "provide a summary of a text"
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 7,
            subGoals: [
              "determine the figurative, connotative, and technical meanings of words",
              "determine an author's point of view or purpose in a text",
            ],
          },
          {
            title: "Integrating Knowledge and Ideas",
            level: 7,
            subGoals: [
              "trace and evaluate the argument and specific claims in a text",
              "compare and contrast one author's presentation of events with that of another",
            ],
          },
          {
            title: "Identifying key Ideas and Details",
            level: 8,
            subGoals: [
              "cite several pieces of textual evidence to support analysis of what the text says",
              "provide an objective summary of the text",
              "analyze the interactions between individuals, events, and ideas in a text"
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 8,
            subGoals: [
              "determine the figurative, connotative, and technical meaning of words in a text",
              "analyze the impact of a specific word choice on meaning and tone",
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 8,
            subGoals: [
              "delineate and evaluate the argument and specific claims in a text, assessing whether the reasoning is sound and the evidence is relevant and sufficient",
              "analyze a case in which two or more texts provide conflicting information on the same topic and identify where the texts disagree on matters of fact or interpretation"
            ],
          },
          {
            title: "Identifying key Ideas and Details",
            level: 9,
            subGoals: [
              "determine a central idea of a text and analyze its development over the course of the text",
              "provide an objective summary of the text"
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 9,
            subGoals: [
              "determine the figurative, connotative, and technical meanings of words",
              "analyze the impact of specific word choices on meaning and tone",
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 9,
            subGoals: [
              "delineate and evaluate the argument and specific claims in a text, assessing whether the reasoning is sound and the evidence is relevant and sufficient",
              "analyze a case in which two or more texts provide conflicting information on the same topic and identify where the texts disagree on matters of fact or interpretation"
            ],
          },
        ],
      },
      {
        isUpdated: false,
        limit: false,
        questionID: 19,
        subTitle: "Struggles",
        description: "Select 1-3 smart goals and up to 3 goals for the smart goal",
        question: "Which areas in reading comprehension does name still struggle with?",
        select: "Accordian",
        dependQuestion: 2,
        answered: false,
        goalQues: [],
        sublimit: 3,
        suberGoals: [
          {
            title: "Identifying key ideas and details",
            level: 1,
            subGoals: [
              "answer ‘wh’ questions based on a picture",
              "identify the main topic and key details based on a picture.",
              "describe connections between individuals or events based on picturest",
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 1,
            subGoals: [
              "identify the front cover, back cover, and title page of a book",
              "name the author and illustrator of a text",
            ],
          },
          {
            title: "demonstrating basic comprehension skills",
            level: 2,
            subGoals: [
              "answer ‘wh’ questions based on tex",
              "identify the main idea of a text.",
              "retell key details of a text",
            ],
          },
          {
            title: "Comprehending text",
            level: 2,
            subGoals: [
              "sequence events of a text",
              "form predictions based on a story",
            ],
          },
          {
            title: "Integrating Knowledge and Ideas",
            level: 2,
            subGoals: [
              "compare and contrast details of a story.",
              "describe the connection between individuals or events, ideas in a text",
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 3,
            subGoals: [
              "answer questions as who, what, where, when, why, and how",
              "identify the main idea",
              "describe connections of elements in a text",
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 3,
            subGoals: [
              "determine the meaning of words and phrases in a text.",
              "identify the main purpose of a text.",
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 3,
            subGoals: [
              "compare and contrast points based on two texts",
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 4,
            subGoals: [
              "answer questions based on text and justify responses",
              "determine the main idea and supporting details",
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 5,
            subGoals: [
              "justify responses when drawing inferences based on text",
              "determine the main idea of a text and provide supporting details",
              "summarize text"
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 5,
            subGoals: [
              "write an essay based on text read"
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 6,
            subGoals: [
              "quote accurately from a text when explaining what the text says explicitly and when drawing inferences from the text",
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 6,
            subGoals: [
              "locate information or answers in a text",
              "integrate information from several texts on the same topic to write or speak about the subject knowledgeably."
            ],
          },
          {
            title: "Identifying key ideas and details",
            level: 7,
            subGoals: [
              "cite textual evidence to support analysis",
              "cite textual evidence to support inferences drawn from the text. Determine a central idea of a text and how it is conveyed through particular details",
              "provide a summary of a text"
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 7,
            subGoals: [
              "determine the figurative, connotative, and technical meanings of words",
              "determine an author's point of view or purpose in a text",
            ],
          },
          {
            title: "Integrating Knowledge and Ideas",
            level: 7,
            subGoals: [
              "trace and evaluate the argument and specific claims in a text",
              "compare and contrast one author's presentation of events with that of another",
            ],
          },
          {
            title: "Identifying key Ideas and Details",
            level: 8,
            subGoals: [
              "cite several pieces of textual evidence to support analysis of what the text says",
              "provide an objective summary of the text",
              "analyze the interactions between individuals, events, and ideas in a text"
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 8,
            subGoals: [
              "determine the figurative, connotative, and technical meaning of words in a text",
              "analyze the impact of a specific word choice on meaning and tone",
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 8,
            subGoals: [
              "delineate and evaluate the argument and specific claims in a text, assessing whether the reasoning is sound and the evidence is relevant and sufficient",
              "analyze a case in which two or more texts provide conflicting information on the same topic and identify where the texts disagree on matters of fact or interpretation"
            ],
          },
          {
            title: "Identifying key Ideas and Details",
            level: 9,
            subGoals: [
              "determine a central idea of a text and analyze its development over the course of the text",
              "provide an objective summary of the text"
            ],
          },
          {
            title: "Demonstrating understanding of Craft and Structure",
            level: 9,
            subGoals: [
              "determine the figurative, connotative, and technical meanings of words",
              "analyze the impact of specific word choices on meaning and tone",
            ],
          },
          {
            title: "Integrating knowledge and ideas",
            level: 9,
            subGoals: [
              "delineate and evaluate the argument and specific claims in a text, assessing whether the reasoning is sound and the evidence is relevant and sufficient",
              "analyze a case in which two or more texts provide conflicting information on the same topic and identify where the texts disagree on matters of fact or interpretation"
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Math",
    questions: [
      {
        isUpdated: false,
        questionID: 20,
        subTitle: "Math Skills",
        description: "Select 1",
        question: "How would you rate name's math skills?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "very poor",
            check: false,
          },
          {
            id: 2,
            value: "poor",
            check: false,
          },
          {
            id: 3,
            value: "slightly below expected grade level",
            check: false,
          },
          {
            id: 3,
            value: "at expected grade level",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 21,
        subTitle: "Grade Level",
        description: "Select 1",
        question: "Select the grade level from the following",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "kindergarten",
            check: false,
            isHidden: false,
            level: 1,
          },
          {
            id: 2,
            value: "first grade",
            check: false,
            isHidden: false,
            level: 2,
          },
          {
            id: 3,
            value: "second grade",
            check: false,
            isHidden: false,
            level: 3,
          },
          {
            id: 3,
            value: "third grade",
            check: false,
            isHidden: false,
            level: 4,
          },
          {
            id: 3,
            value: "fourth grade",
            check: false,
            isHidden: false,
            level: 5,
          },
          {
            id: 3,
            value: "fifth grade",
            check: false,
            isHidden: false,
            level: 6,
          },
          {
            id: 3,
            value: "sixth grade",
            check: false,
            isHidden: false,
            level: 7,
          },
          {
            id: 3,
            value: "seventh grade",
            check: false,
            isHidden: false,
            level: 8,
          },
          {
            id: 3,
            value: "eighth grade",
            check: false,
            isHidden: false,
            level: 9,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 22,
        subTitle: "Intervention",
        description: "Select 1",
        question: "Which type of aids do you use during intervention?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "visual aids",
            check: false,
          },
          {
            id: 2,
            value: "tactile manipulatives",
            check: false,
          },
          {
            id: 3,
            value: "base ten blocks",
            check: false,
          },
          {
            id: 3,
            value: "counters",
            check: false,
          },
          {
            id: 3,
            value: "hands-on techniques",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 23,
        subTitle: "Progress",
        description: "Select 1",
        question: "How would you describe name's progress in math?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "mild",
            check: false,
          },
          {
            id: 2,
            value: "moderate",
            check: false,
          },
          {
            id: 3,
            value: "significant",
            check: false,
          },
          {
            id: 3,
            value: "great",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        limit: false,
        questionID: 24,
        subTitle: "Math Goals",
        description: "Select 3 smart goals and 1 goal per smart goal",
        question: "What are your math goals for the year?",
        select: "Accordian",
        answered: false,
        dependQuestion: 1,
        sublimit: 1,
        goalLimit: 3,
        goalQues: [],
        suberGoals: [
          {
            title: "Know number names and the count sequence",
            level: 1,
            subGoals: [
              "count to 100 by ones",
              "count to 100 by tens",
              "count forward beginning from a given number (instead of having to begin at 1)",
              "write numbers from 0 to 20",
            ],
          },
          {
            title: "Count to tell the number of objects",
            level: 1,
            subGoals: [
              "connect counting to cardinality",
              "count with 1:1 correspondence"
            ],
          },
          {
            title: "Compare numbers",
            level: 1,
            subGoals: [
              "identify physical groups that are  greater than, less than, or equal to",
              "compare two numbers between 1 and 10 presented as written numerals",
            ],
          },
          {
            title:
              'Understand addition and subtraction concepts',
            level: 1,
            subGoals: [
              "represent addition and subtraction with objects",
              "solve addition and subtraction word problems",
              "add and subtract within 10",
              "find the number that makes 10 when added to the given number",
              "fluently add and subtract within 5."
            ],
          },
          {
            title:
              'Understand addition as putting together and adding to and understand subtraction as taking apart and taking from"',
            level: 1,
            subGoals: [
              "represent addition and subtraction with objects, fingers, mental images, drawings, and sounds",
              "solve addition and subtraction word problems, and add and subtract within 10",
              "find the number that makes 10 when added to the given number",
              "fluently add and subtract within 5",
            ],
          },
          {
            title: "Work with numbers 11-19 to gain foundations for place value",
            level: 1,
            subGoals: ["compose and decompose numbers from 11 to 19 into ten ones and some further ones"],
          },
          {
            title: "Describe and compare measurable attributes",
            level: 1,
            subGoals: [
              "describe measurable attributes of objects, such as length or weight",
              "directly compare two objects with a measurable attribute in common"
            ],
          },
          {
            title:
              'Understand place value concepts',
            level: 1,
            subGoals: [
              "compose and decompose numbers into tens and one",
            ],
          },
          {
            title: "Identify and describe shapes",
            level: 1,
            subGoals: [
              "describe the relative positions of objects",
              "correctly name shapes",
            ],
          },
          {
            title: "Solve word problems",
            level: 2,
            subGoals: [
              "solve word problems involving addition within 20",
              "solve word problems involving subtraction within 20"
            ],
          },
          {
            title: "Add and subtract",
            level: 2,
            subGoals: [
              "relate counting to addition and subtraction",
              "add within 20",
              "subtract within 20",
              "demonstrate fluency for addition within 10",
              "demonstrate fluency for subtraction within 10",
            ],
          },
          {
            title: "Extend the counting sequence",
            level: 2,
            subGoals: [
              "count to 120, starting at any number",
              "read and write numbers up to 120",
              "represent a number of objects with a written numeral up to 120.",
            ],
          },
          {
            title: "Understand place value",
            level: 2,
            subGoals: [
              "understand the concept of tens and ones",
              "compare two two-digit numbers using symbols >, =, and <"
            ],
          },
          {
            title: "Add and subtract",
            level: 2,
            subGoals: [
              "add a two-digit number and a one-digit number",
              "mentally find 10 more or 10 less than a number",
              "Subtract multiples of 10.",
            ],
          },
          {
            title: "Use place value understanding and properties of operations to add and subtract",
            level: 2,
            subGoals: [
              "understand that in adding two-digit numbers, one adds tens and tens, ones and ones; and sometimes it is necessary to compose a ten",
              "add a two-digit number and a one-digit number",
              "mentally find 10 more or 10 less than a double-digit number, without having to count",
              "subtract multiples of 10 in the range 10-90 from multiples of 10 in the range 10-90",
            ],
          },
          {
            title: "Measure lengths indirectly",
            level: 2,
            subGoals: [
              "order three objects by length",
              "compare the lengths of two objects indirectly by using a third object",
            ],
          },
          {
            title: "Tell and write time",
            level: 2,
            subGoals: [
              "label the hour hand and minute hand on an analog clock",
              "tell time in hours using analog clocks",
              "tell time in half hours and hours using analog clocks",
              "write time in hours using analog and digital clocks",
              "write time in half hours and hours using analog and digital clocks",
            ],
          },
          {
            title: "Represent and solve problems involving addition and subtraction",
            level: 3,
            subGoals: [
              "use addition within 100 to solve one-step and two-step word problems involving situations including addition",
              "use subtraction within 100 to solve one-step and two-step word problems involving situations including subtraction",
              "use addition and subtraction within 100 to solve one and two-step word problems involving situations including addition and subtraction",
            ],
          },
          {
            title: "Solve word problems",
            level: 3,
            subGoals: [
              "solve one-step and two-step word problems involving addition",
              "solve one-step and two-step word problems involving subtraction",
              "solve one and two-step word problems involving addition and subtraction",
            ],
          },
          {
            title: "Add and subtract within 20",
            level: 3,
            subGoals: [
              "fluently add within 20 using mental strategies",
              "fluently subtract within 20 using mental strategies",
              "know from memory all sums of two one-digit numbers",
            ],
          },
          {
            title: "Understand place value",
            level: 3,
            subGoals: [
              "understand hundreds, tens, and ones",
              "count within 1000",
              "skip-count by 5s, 10s, and 100s",
              "read and write numbers to 1000",
              "compare two three-digit numbers using >, =, and < symbols"
            ],
          },
          {
            title: "Add and subtract",
            level: 3,
            subGoals: [
              "regroup in addition",
              "regroup in subtraction",
              "fluently add and subtract within 100",
              "add up to four two-digit numbers",
              "add and subtract within 1000",
              "mentally add 10 or 100 to a given number",
              "mentally subtract 10 or 100 from a given number",
            ],
          },
          {
            title: "Measure and estimate lengths in standard units",
            level: 3,
            subGoals: [
              "measure the length of an object using a ruler",
              "measure to determine how much longer one object is than another"
            ],
          },
          {
            title: "Work with time and money",
            level: 3,
            subGoals: [
              "tell time to the hour on an analog clock",
              "tell time to the half hour on an analog clock",
              "tell time to the quarter on an analog clock",
              "tell time to the nearest five minutes on an analog clock",
              "label pennies, nickels, dimes and quarters",
              "identify the value of pennies, nickels, dimes and quarters",
              "add the value of a sum of pennies up to one dollar",
              "add the value of a sum of nickels up to one dollar",
              "add the value of a sum of dimes up to one dollar",
              "add the value of a sum of quarters up to one dollar",
              "add the value of a sum of coins, including pennies, nickels, dimes and quarters up to one dollar",
              "add dollar bills accurately",
            ],
          },
          {
            title: "Perform multi-digit arithmetic",
            level: 4,
            subGoals: [
              "round whole numbers to the nearest 10 or 100",
              "fluently add and subtract within 1000",
              "multiply one-digit numbers by multiples of 10"
            ],
          },
          {
            title: "Represent and solve problems involving multiplication and division",
            level: 4,
            subGoals: [
              "demonstrate understanding of multiplication as repeated addition",
              "interpret products of whole numbers",
              "solve word problems in situations involving equal groups, arrays, and measurement quantities",
              "determine the unknown number in a multiplication or division equation",
            ],
          },
          {
            title: "Multiply and divide within 100",
            level: 4,
            subGoals: [
              "multiply a one-digit number by 0",
              "multiply a one-digit number by 1",
              "multiply a one-digit number by 2",
              "multiply a one-digit number by 3",
              "multiply a one-digit number by 4",
              "multiply a one-digit number by 5",
              "multiply a one-digit number by 6",
              "multiply a one-digit number by 7",
              "multiply a one-digit number by 8",
              "multiply a one-digit number by 9",
              "multiply a one-digit number by 10",
              "fluently multiply within 100",
              "know from memory all products of two one-digit numbers",
              "determine the quotients when a two-digit number is divided by 1",
              "determine the quotients when a two-digit number is divided by 2",
              "determine the quotients when a two-digit number is divided by 3",
              "determine the quotients when a two-digit number is divided by 4",
              "determine the quotients when a two-digit number is divided by 5",
              "determine the quotients when a two-digit number is divided by 6",
              "determine the quotients when a two-digit number is divided by 7",
              "determine the quotients when a two-digit number is divided by 8",
              "determine the quotients when a two-digit number is divided by 9",
              "determine the quotients when a two-digit number is divided by 10",
            ],
          },
          {
            title: "Solve problems involving the four operations",
            level: 4,
            subGoals: ["solve two-step word problems using addition, subtraction, and multiplication operations"],
          },
          {
            title: "Understand fraction concepts",
            level: 4,
            subGoals: [
              "understand a fraction as a part over whole",
              "understand a fraction as a number on the number line",
              "represent fractions on a number line diagram",
              "compare fractions by reasoning about their size",
              "understand equivalent fractions",
              "generate simple equivalent fractions, such as ½ equals 2/4",
              "write a fraction to represent a picture",
            ],
          },
          {
            title: "Work with time",
            level: 4,
            subGoals: [
              "tell and write time to the nearest minute",
              "solve word problems involving addition and subtraction of time intervals in minutes",
            ],
          },
          {
            title: "Represent and interpret data",
            level: 4,
            subGoals: [
              "draw a scaled picture graph and a scaled bar graph to represent a data set with several categories",
              "solve one- and two-step problems using information presented in scaled bar graphs"
            ],
          },
          {
            title: "Use the four operations with whole numbers to solve problems",
            level: 5,
            subGoals: ["solve multistep word problems using the four operations"],
          },
          {
            title: "understand factors and multiples.",
            level: 5,
            subGoals: [
              "find all factor pairs for a whole number in the range 1-100",
              "determine whether a number is a multiple of a given number",
              "determine whether a number is prime or composite",
            ],
          },
          {
            title: "Perform multi-digit arithmetic",
            level: 5,
            subGoals: [
              "multiply a four digit number by a one-digit number.",
              "multiply two two-digit numbers with or without regrouping",
              "find whole-number quotients and remainders with up to four-digit dividends and one-digit divisors",
            ],
          },
          {
            title: "Solve problems involving fractions",
            level: 5,
            subGoals: [
              "understand the concept of addition and subtraction of fractions",
              "add and subtract mixed numbers with like denominators",
              "solve word problems involving addition and subtraction of fractions.",
              "multiply a fraction by a whole number",
              "solve word problems involving multiplication of a fraction by a whole number",
            ],
          },
          {
            title: "Understand decimal notation for fractions",
            level: 5,
            subGoals: [
              "add two fractions with denominators 10 and 100",
              "use decimal notation for fractions with denominators 10 or 100",
              "compare two decimals to hundredths",
            ],
          },
          {
            title: "Solve problems involving measurement and conversion",
            level: 5,
            subGoals: [
              "know relative sizes of measurement units within one system of units",
              "express measurements in a larger unit in terms of a smaller unit",
              "solve word problems involving distances, intervals of time, volumes, masses, and money.",
            ],
          },
          {
            title: "Represent and interpret data",
            level: 5,
            subGoals: [
              "make a line plot to display a data set of measurements in fractions of a unit"
            ],
          },
          {
            title: "Write and interpret numerical expressions",
            level: 6,
            subGoals: ["follow the order of operations when solving multi-step equations"],
          },
          {
            title: "Understand the place value system",
            level: 6,
            subGoals: [
              "read, write, and compare decimals to thousandths",
              "compare two decimals to thousandths using >, =, and < symbols",
              "round decimals to any place"
            ],
          },
          {
            title: "Perform operations with decimals",
            level: 6,
            subGoals: [
              "multiply numbers with decimals",
              "divide numbers with decimals",
              "add and subtract numbers with decimals",
            ],
          },
          {
            title: "Add and subtract fractions",
            level: 6,
            subGoals: [
              "add and subtract fractions with unlike denominators",
              "solve word problems involving addition and subtraction of fractions",
            ],
          },
          {
            title: "Multiply and divide fractions",
            level: 6,
            subGoals: [
              "multiply a fraction or whole number by a fraction",
              "solve real world problems involving multiplication of fractions and mixed numbers",
              "divide fractions by whole numbers and whole numbers by fractions",
              "solve word  problems involving division of fraction"
            ],
          },
          {
            title: "Convert like measurement units within a given measurement system",
            level: 6,
            subGoals: [
              "convert among different-sized standard measurement units within a given measurement system",
              "solve multi-step real world problems involving converting measurements units",
            ],
          },
          {
            title: "Understand concepts of volume",
            level: 6,
            subGoals: [
              "Solve real world and mathematical problems involving volume.",
              "find the volume of rectangular prisms using the formula V = l × w × h",
            ],
          },
          {
            title: "Understand ratio concepts and use ratio reasoning to solve problems",
            level: 7,
            subGoals: [
              "demonstrate understanding of the concept of a ratio",
              "use ratio and rate reasoning to solve real-world and mathematical problems",
              "identify equivalent ratios and find missing values in tables including ratios",
              "solve unit rate problems",
              "find a percent of a quantity as a rate per 100",
            ],
          },
          {
            title: "Find common factors and multiples",
            level: 7,
            subGoals: [
              "find the greatest common factor of two whole numbers less than or equal to 100",
              "find the least common multiple of two whole numbers less than or equal to 12",
            ],
          },
          {
            title: "Use arithmetic with rational numbers",
            level: 7,
            subGoals: [
              "use positive and negative numbers to represent quantities in real-world contexts",
              "recognize opposite signs of numbers and its location on a number line.",
              "find and position integers on a number line diagram",
              "find and position pairs of integers on a coordinate plane",
              "solve word problems by graphing points in all four quadrants of the coordinate plane"
            ],
          },
          {
            title: "Demonstrate understanding of algebraic expressions",
            level: 7,
            subGoals: [
              "write and evaluate numerical expressions involving exponents",
              "write, read, and evaluate expressions including variables",
              "write expressions that record operations with numbers and variable",
            ],
          },
          {
            title: "Solve one-variable equations and inequalities",
            level: 7,
            subGoals: [
              "write expressions when solving a real-world or mathematical problem",
              "solve single-step equations",
            ],
          },
          {
            title: "Solve problems involving area, surface area, and volume.",
            level: 7,
            subGoals: [
              "find the area of triangles and polygons",
              "find the volume of a right rectangular prism with fractional edge lengths",
              "draw polygons in the coordinate plane given coordinates for the vertice",
            ],
          },
          {
            title: "Summarize and describe distributions",
            level: 7,
            subGoals: [
              "display numerical data in plots on a number line, including dot plots, histograms, and box plots",
              "summarize numerical data sets in relation to their context",
            ],
          },
          {
            title: "Analyze proportional relationship",
            level: 8,
            subGoals: [
              "compute unit rates associated with ratios of fractions",
              "recognize and represent proportional relationships",
              "identify the unit rate in tables, graphs, equations and diagrams",
              "represent proportional relationships by equations",
              "solve multistep ratio and percent problems"
            ],
          },
          {
            title: "Use of operations with fractions",
            level: 8,
            subGoals: [
              "add and subtract rational numbers",
              "multiply and divide rational numbers",
              "convert a rational number to a decimal using long division",
              "solve problems involving the four operations with rational numbers",
            ],
          },
          {
            title: "Generate equivalent expressions",
            level: 8,
            subGoals: ["add, subtract, factor, and expand linear expressions with rational coefficients"],
          },
          {
            title: "Solve problems using numerical and algebraic expressions and equations",
            level: 8,
            subGoals: [
              "solve multi-step problems with positive and negative rational numbers",
              "use variables to construct simple equations and inequalities to solve problems",
              "solve word problems in the form px + q = r and p(x + q) = r",
              "solve inequalities in the form px + q > r or px + q < r"
            ],
          },
          {
            title: "Solve problems involving angle measure, area, surface area, and volume",
            level: 8,
            subGoals: [
              "solve problems involving using formulas for the area and circumference of a circle",
              "write and solve simple equations for an unknown angle in a figure",
              "solve problems involving area, volume and surface area of two- and three-dimensional objects.",
            ],
          },
          {
            title: "Investigate chance processes and develop, use, and evaluate probability models",
            level: 8,
            subGoals: [
              "develop a probability model and use it to find probabilities of events",
              "compare probabilities from a model to observed frequencies",
              "find probabilities of compound events using organized lists, tables, tree diagrams, and simulation",
            ],
          },
          {
            title: "Work with radicals and integer exponents",
            level: 9,
            subGoals: [
              "generate equivalent numerical expressions",
              "use square root and cube root symbols to represent solutions",
              "estimate quantities using single digit numbers times an integer power of 10",
              "perform operations with numbers expressed in scientific notation",
            ],
          },
          {
            title: "Understand the connections between proportional relationships, lines, and linear equations",
            level: 9,
            subGoals: [
              "graph proportional relationships and interpret the unit rate",
              "compare two different proportional relationships",
            ],
          },
          {
            title: "Analyze and solve linear equations and pairs",
            level: 9,
            subGoals: [
              "solve linear equations in one variable",
              "solve linear equations with coefficients.",
              "solve systems of two linear equations in two variables algebraically",
              "solve problems leading to two linear equations in two variables"
            ],
          },
          {
            title: "Use physical models and transparencies to understand congruence and similarity",
            level: 9,
            subGoals: [
              "describe the effect of dilations, translations, rotations, and reflections on two-dimensional figures using coordinates",
            ],
          },
          {
            title: "Apply the Pythagorean Theorem",
            level: 9,
            subGoals: [
              "determine unknown side lengths in right triangles",
              "find the distance between two points in a coordinate system"
            ],
          },
          {
            title: "Solve problems involving volume of cylinders, cones, and spheres.",
            level: 9,
            subGoals: [
              "use formulas for the volumes of cones, cylinders, and spheres"
            ],
          },
        ],
      },
      {
        isUpdated: false,
        limit: false,
        questionID: 25,
        subTitle: "Struggles",
        description: "Select 3 smart goals and 1 goal per smart goal",
        question: "Which areas in math goals does name still struggle with?",
        select: "Accordian",
        dependQuestion: 1,
        answered: false,
        goalQues: [],
        sublimit: 1,
        goalLimit: 3,
        removeSuperGoals: [
          {
            hide: "Work with time and money",
            titles: [
              "solve word problems involving dollar bills, quarters, dimes, nickels, and pennies, using $ and ¢ symbols appropriately",
            ],
          },
          {
            hide: "Represent and solve problems involving multiplication and division",
            titles: ["demonstrate understanding of division as a number divided into equal groups"],
          },
          {
            hide: "Multiply and divide within 100",
            titles: [
              "determine the quotients when a two-digit number is divided by 1",
              "determine the quotients when a two-digit number is divided by 2",
              "determine the quotients when a two-digit number is divided by 3",
              "determine the quotients when a two-digit number is divided by 4",
              "determine the quotients when a two-digit number is divided by 5",
              "determine the quotients when a two-digit number is divided by 6",
              "determine the quotients when a two-digit number is divided by 7",
              "determine the quotients when a two-digit number is divided by 8",
              "determine the quotients when a two-digit number is divided by 9",
              "determine the quotients when a two-digit number is divided by 10",
            ],
          },
        ],
        suberGoals: [
          {
            title: "Know number names and the count sequence",
            level: 1,
            subGoals: [
              "count to 100 by ones",
              "count to 100 by tens",
              "count forward beginning from a given number (instead of having to begin at 1)",
              "write numbers from 0 to 20",
            ],
          },
          {
            title: "Count to tell the number of objects",
            level: 1,
            subGoals: [
              "connect counting to cardinality",
              "count with 1:1 correspondence"
            ],
          },
          {
            title: "Compare numbers",
            level: 1,
            subGoals: [
              "identify physical groups that are  greater than, less than, or equal to",
              "compare two numbers between 1 and 10 presented as written numerals",
            ],
          },
          {
            title:
              'Understand addition and subtraction concepts',
            level: 1,
            subGoals: [
              "represent addition and subtraction with objects",
              "solve addition and subtraction word problems",
              "add and subtract within 10",
              "find the number that makes 10 when added to the given number",
              "fluently add and subtract within 5."
            ],
          },
          {
            title:
              'Understand addition as putting together and adding to and understand subtraction as taking apart and taking from"',
            level: 1,
            subGoals: [
              "represent addition and subtraction with objects, fingers, mental images, drawings, and sounds",
              "solve addition and subtraction word problems, and add and subtract within 10",
              "find the number that makes 10 when added to the given number",
              "fluently add and subtract within 5",
            ],
          },
          {
            title: "Work with numbers 11-19 to gain foundations for place value",
            level: 1,
            subGoals: ["compose and decompose numbers from 11 to 19 into ten ones and some further ones"],
          },
          {
            title: "Describe and compare measurable attributes",
            level: 1,
            subGoals: [
              "describe measurable attributes of objects, such as length or weight",
              "directly compare two objects with a measurable attribute in common"
            ],
          },
          {
            title:
              'Understand place value concepts',
            level: 1,
            subGoals: [
              "compose and decompose numbers into tens and one",
            ],
          },
          {
            title: "Identify and describe shapes",
            level: 1,
            subGoals: [
              "describe the relative positions of objects",
              "correctly name shapes",
            ],
          },
          {
            title: "Solve word problems",
            level: 2,
            subGoals: [
              "solve word problems involving addition within 20",
              "solve word problems involving subtraction within 20"
            ],
          },
          {
            title: "Add and subtract",
            level: 2,
            subGoals: [
              "relate counting to addition and subtraction",
              "add within 20",
              "subtract within 20",
              "demonstrate fluency for addition within 10",
              "demonstrate fluency for subtraction within 10",
            ],
          },
          {
            title: "Extend the counting sequence",
            level: 2,
            subGoals: [
              "count to 120, starting at any number",
              "read and write numbers up to 120",
              "represent a number of objects with a written numeral up to 120.",
            ],
          },
          {
            title: "Understand place value",
            level: 2,
            subGoals: [
              "understand the concept of tens and ones",
              "compare two two-digit numbers using symbols >, =, and <"
            ],
          },
          {
            title: "Add and subtract",
            level: 2,
            subGoals: [
              "add a two-digit number and a one-digit number",
              "mentally find 10 more or 10 less than a number",
              "Subtract multiples of 10.",
            ],
          },
          {
            title: "Use place value understanding and properties of operations to add and subtract",
            level: 2,
            subGoals: [
              "understand that in adding two-digit numbers, one adds tens and tens, ones and ones; and sometimes it is necessary to compose a ten",
              "add a two-digit number and a one-digit number",
              "mentally find 10 more or 10 less than a double-digit number, without having to count",
              "subtract multiples of 10 in the range 10-90 from multiples of 10 in the range 10-90",
            ],
          },
          {
            title: "Measure lengths indirectly",
            level: 2,
            subGoals: [
              "order three objects by length",
              "compare the lengths of two objects indirectly by using a third object",
            ],
          },
          {
            title: "Tell and write time",
            level: 2,
            subGoals: [
              "label the hour hand and minute hand on an analog clock",
              "tell time in hours using analog clocks",
              "tell time in half hours and hours using analog clocks",
              "write time in hours using analog and digital clocks",
              "write time in half hours and hours using analog and digital clocks",
            ],
          },
          {
            title: "Represent and solve problems involving addition and subtraction",
            level: 3,
            subGoals: [
              "use addition within 100 to solve one-step and two-step word problems involving situations including addition",
              "use subtraction within 100 to solve one-step and two-step word problems involving situations including subtraction",
              "use addition and subtraction within 100 to solve one and two-step word problems involving situations including addition and subtraction",
            ],
          },
          {
            title: "Solve word problems",
            level: 3,
            subGoals: [
              "solve one-step and two-step word problems involving addition",
              "solve one-step and two-step word problems involving subtraction",
              "solve one and two-step word problems involving addition and subtraction",
            ],
          },
          {
            title: "Add and subtract within 20",
            level: 3,
            subGoals: [
              "fluently add within 20 using mental strategies",
              "fluently subtract within 20 using mental strategies",
              "know from memory all sums of two one-digit numbers",
            ],
          },
          {
            title: "Understand place value",
            level: 3,
            subGoals: [
              "understand hundreds, tens, and ones",
              "count within 1000",
              "skip-count by 5s, 10s, and 100s",
              "read and write numbers to 1000",
              "compare two three-digit numbers using >, =, and < symbols"
            ],
          },
          {
            title: "Add and subtract",
            level: 3,
            subGoals: [
              "regroup in addition",
              "regroup in subtraction",
              "fluently add and subtract within 100",
              "add up to four two-digit numbers",
              "add and subtract within 1000",
              "mentally add 10 or 100 to a given number",
              "mentally subtract 10 or 100 from a given number",
            ],
          },
          {
            title: "Measure and estimate lengths in standard units",
            level: 3,
            subGoals: [
              "measure the length of an object using a ruler",
              "measure to determine how much longer one object is than another"
            ],
          },
          {
            title: "Work with time and money",
            level: 3,
            subGoals: [
              "tell time to the hour on an analog clock",
              "tell time to the half hour on an analog clock",
              "tell time to the quarter on an analog clock",
              "tell time to the nearest five minutes on an analog clock",
              "label pennies, nickels, dimes and quarters",
              "identify the value of pennies, nickels, dimes and quarters",
              "add the value of a sum of pennies up to one dollar",
              "add the value of a sum of nickels up to one dollar",
              "add the value of a sum of dimes up to one dollar",
              "add the value of a sum of quarters up to one dollar",
              "add the value of a sum of coins, including pennies, nickels, dimes and quarters up to one dollar",
              "add dollar bills accurately",
            ],
          },
          {
            title: "Perform multi-digit arithmetic",
            level: 4,
            subGoals: [
              "round whole numbers to the nearest 10 or 100",
              "fluently add and subtract within 1000",
              "multiply one-digit numbers by multiples of 10"
            ],
          },
          {
            title: "Represent and solve problems involving multiplication and division",
            level: 4,
            subGoals: [
              "demonstrate understanding of multiplication as repeated addition",
              "interpret products of whole numbers",
              "solve word problems in situations involving equal groups, arrays, and measurement quantities",
              "determine the unknown number in a multiplication or division equation",
            ],
          },
          {
            title: "Multiply and divide within 100",
            level: 4,
            subGoals: [
              "multiply a one-digit number by 0",
              "multiply a one-digit number by 1",
              "multiply a one-digit number by 2",
              "multiply a one-digit number by 3",
              "multiply a one-digit number by 4",
              "multiply a one-digit number by 5",
              "multiply a one-digit number by 6",
              "multiply a one-digit number by 7",
              "multiply a one-digit number by 8",
              "multiply a one-digit number by 9",
              "multiply a one-digit number by 10",
              "fluently multiply within 100",
              "know from memory all products of two one-digit numbers",
              "determine the quotients when a two-digit number is divided by 1",
              "determine the quotients when a two-digit number is divided by 2",
              "determine the quotients when a two-digit number is divided by 3",
              "determine the quotients when a two-digit number is divided by 4",
              "determine the quotients when a two-digit number is divided by 5",
              "determine the quotients when a two-digit number is divided by 6",
              "determine the quotients when a two-digit number is divided by 7",
              "determine the quotients when a two-digit number is divided by 8",
              "determine the quotients when a two-digit number is divided by 9",
              "determine the quotients when a two-digit number is divided by 10",
            ],
          },
          {
            title: "Solve problems involving the four operations",
            level: 4,
            subGoals: ["solve two-step word problems using addition, subtraction, and multiplication operations"],
          },
          {
            title: "Understand fraction concepts",
            level: 4,
            subGoals: [
              "understand a fraction as a part over whole",
              "understand a fraction as a number on the number line",
              "represent fractions on a number line diagram",
              "compare fractions by reasoning about their size",
              "understand equivalent fractions",
              "generate simple equivalent fractions, such as ½ equals 2/4",
              "write a fraction to represent a picture",
            ],
          },
          {
            title: "Work with time",
            level: 4,
            subGoals: [
              "tell and write time to the nearest minute",
              "solve word problems involving addition and subtraction of time intervals in minutes",
            ],
          },
          {
            title: "Represent and interpret data",
            level: 4,
            subGoals: [
              "draw a scaled picture graph and a scaled bar graph to represent a data set with several categories",
              "solve one- and two-step problems using information presented in scaled bar graphs"
            ],
          },
          {
            title: "Use the four operations with whole numbers to solve problems",
            level: 5,
            subGoals: ["solve multistep word problems using the four operations"],
          },
          {
            title: "understand factors and multiples.",
            level: 5,
            subGoals: [
              "find all factor pairs for a whole number in the range 1-100",
              "determine whether a number is a multiple of a given number",
              "determine whether a number is prime or composite",
            ],
          },
          {
            title: "Perform multi-digit arithmetic",
            level: 5,
            subGoals: [
              "multiply a four digit number by a one-digit number.",
              "multiply two two-digit numbers with or without regrouping",
              "find whole-number quotients and remainders with up to four-digit dividends and one-digit divisors",
            ],
          },
          {
            title: "Solve problems involving fractions",
            level: 5,
            subGoals: [
              "understand the concept of addition and subtraction of fractions",
              "add and subtract mixed numbers with like denominators",
              "solve word problems involving addition and subtraction of fractions.",
              "multiply a fraction by a whole number",
              "solve word problems involving multiplication of a fraction by a whole number",
            ],
          },
          {
            title: "Understand decimal notation for fractions",
            level: 5,
            subGoals: [
              "add two fractions with denominators 10 and 100",
              "use decimal notation for fractions with denominators 10 or 100",
              "compare two decimals to hundredths",
            ],
          },
          {
            title: "Solve problems involving measurement and conversion",
            level: 5,
            subGoals: [
              "know relative sizes of measurement units within one system of units",
              "express measurements in a larger unit in terms of a smaller unit",
              "solve word problems involving distances, intervals of time, volumes, masses, and money.",
            ],
          },
          {
            title: "Represent and interpret data",
            level: 5,
            subGoals: [
              "make a line plot to display a data set of measurements in fractions of a unit"
            ],
          },
          {
            title: "Write and interpret numerical expressions",
            level: 6,
            subGoals: ["follow the order of operations when solving multi-step equations"],
          },
          {
            title: "Understand the place value system",
            level: 6,
            subGoals: [
              "read, write, and compare decimals to thousandths",
              "compare two decimals to thousandths using >, =, and < symbols",
              "round decimals to any place"
            ],
          },
          {
            title: "Perform operations with decimals",
            level: 6,
            subGoals: [
              "multiply numbers with decimals",
              "divide numbers with decimals",
              "add and subtract numbers with decimals",
            ],
          },
          {
            title: "Add and subtract fractions",
            level: 6,
            subGoals: [
              "add and subtract fractions with unlike denominators",
              "solve word problems involving addition and subtraction of fractions",
            ],
          },
          {
            title: "Multiply and divide fractions",
            level: 6,
            subGoals: [
              "multiply a fraction or whole number by a fraction",
              "solve real world problems involving multiplication of fractions and mixed numbers",
              "divide fractions by whole numbers and whole numbers by fractions",
              "solve word  problems involving division of fraction"
            ],
          },
          {
            title: "Convert like measurement units within a given measurement system",
            level: 6,
            subGoals: [
              "convert among different-sized standard measurement units within a given measurement system",
              "solve multi-step real world problems involving converting measurements units",
            ],
          },
          {
            title: "Understand concepts of volume",
            level: 6,
            subGoals: [
              "Solve real world and mathematical problems involving volume.",
              "find the volume of rectangular prisms using the formula V = l × w × h",
            ],
          },
          {
            title: "Understand ratio concepts and use ratio reasoning to solve problems",
            level: 7,
            subGoals: [
              "demonstrate understanding of the concept of a ratio",
              "use ratio and rate reasoning to solve real-world and mathematical problems",
              "identify equivalent ratios and find missing values in tables including ratios",
              "solve unit rate problems",
              "find a percent of a quantity as a rate per 100",
            ],
          },
          {
            title: "Find common factors and multiples",
            level: 7,
            subGoals: [
              "find the greatest common factor of two whole numbers less than or equal to 100",
              "find the least common multiple of two whole numbers less than or equal to 12",
            ],
          },
          {
            title: "Use arithmetic with rational numbers",
            level: 7,
            subGoals: [
              "use positive and negative numbers to represent quantities in real-world contexts",
              "recognize opposite signs of numbers and its location on a number line.",
              "find and position integers on a number line diagram",
              "find and position pairs of integers on a coordinate plane",
              "solve word problems by graphing points in all four quadrants of the coordinate plane"
            ],
          },
          {
            title: "Demonstrate understanding of algebraic expressions",
            level: 7,
            subGoals: [
              "write and evaluate numerical expressions involving exponents",
              "write, read, and evaluate expressions including variables",
              "write expressions that record operations with numbers and variable",
            ],
          },
          {
            title: "Solve one-variable equations and inequalities",
            level: 7,
            subGoals: [
              "write expressions when solving a real-world or mathematical problem",
              "solve single-step equations",
            ],
          },
          {
            title: "Solve problems involving area, surface area, and volume.",
            level: 7,
            subGoals: [
              "find the area of triangles and polygons",
              "find the volume of a right rectangular prism with fractional edge lengths",
              "draw polygons in the coordinate plane given coordinates for the vertice",
            ],
          },
          {
            title: "Summarize and describe distributions",
            level: 7,
            subGoals: [
              "display numerical data in plots on a number line, including dot plots, histograms, and box plots",
              "summarize numerical data sets in relation to their context",
            ],
          },
          {
            title: "Analyze proportional relationship",
            level: 8,
            subGoals: [
              "compute unit rates associated with ratios of fractions",
              "recognize and represent proportional relationships",
              "identify the unit rate in tables, graphs, equations and diagrams",
              "represent proportional relationships by equations",
              "solve multistep ratio and percent problems"
            ],
          },
          {
            title: "Use of operations with fractions",
            level: 8,
            subGoals: [
              "add and subtract rational numbers",
              "multiply and divide rational numbers",
              "convert a rational number to a decimal using long division",
              "solve problems involving the four operations with rational numbers",
            ],
          },
          {
            title: "Generate equivalent expressions",
            level: 8,
            subGoals: ["add, subtract, factor, and expand linear expressions with rational coefficients"],
          },
          {
            title: "Solve problems using numerical and algebraic expressions and equations",
            level: 8,
            subGoals: [
              "solve multi-step problems with positive and negative rational numbers",
              "use variables to construct simple equations and inequalities to solve problems",
              "solve word problems in the form px + q = r and p(x + q) = r",
              "solve inequalities in the form px + q > r or px + q < r"
            ],
          },
          {
            title: "Solve problems involving angle measure, area, surface area, and volume",
            level: 8,
            subGoals: [
              "solve problems involving using formulas for the area and circumference of a circle",
              "write and solve simple equations for an unknown angle in a figure",
              "solve problems involving area, volume and surface area of two- and three-dimensional objects.",
            ],
          },
          {
            title: "Investigate chance processes and develop, use, and evaluate probability models",
            level: 8,
            subGoals: [
              "develop a probability model and use it to find probabilities of events",
              "compare probabilities from a model to observed frequencies",
              "find probabilities of compound events using organized lists, tables, tree diagrams, and simulation",
            ],
          },
          {
            title: "Work with radicals and integer exponents",
            level: 9,
            subGoals: [
              "generate equivalent numerical expressions",
              "use square root and cube root symbols to represent solutions",
              "estimate quantities using single digit numbers times an integer power of 10",
              "perform operations with numbers expressed in scientific notation",
            ],
          },
          {
            title: "Understand the connections between proportional relationships, lines, and linear equations",
            level: 9,
            subGoals: [
              "graph proportional relationships and interpret the unit rate",
              "compare two different proportional relationships",
            ],
          },
          {
            title: "Analyze and solve linear equations and pairs",
            level: 9,
            subGoals: [
              "solve linear equations in one variable",
              "solve linear equations with coefficients.",
              "solve systems of two linear equations in two variables algebraically",
              "solve problems leading to two linear equations in two variables"
            ],
          },
          {
            title: "Use physical models and transparencies to understand congruence and similarity",
            level: 9,
            subGoals: [
              "describe the effect of dilations, translations, rotations, and reflections on two-dimensional figures using coordinates",
            ],
          },
          {
            title: "Apply the Pythagorean Theorem",
            level: 9,
            subGoals: [
              "determine unknown side lengths in right triangles",
              "find the distance between two points in a coordinate system"
            ],
          },
          {
            title: "Solve problems involving volume of cylinders, cones, and spheres.",
            level: 9,
            subGoals: [
              "use formulas for the volumes of cones, cylinders, and spheres"
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Speech and Language",
    questions: [
      {
        isUpdated: false,
        questionID: 26,
        subTitle: "SLP Services",
        question: "Does name receive SLP (Speech Therapy) services?",
        description: "Select 1",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "Not sure",
            check: false,
          },
          {
            id: 2,
            value: "Yes",
            check: false,
          },
          {
            id: 3,
            value: "No",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 27,
        subTitle: "Beneficial",
        question: "Do you think name would benefit from SLP services?",
        description: "Select 1",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 2,
            value: "Yes",
            check: false,
          },
          {
            id: 3,
            value: "No",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 28,
        subTitle: "Frequency",
        question: "How many times a week does name receive SLP services?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        description: "Select 1",
        answered: false,
        options: [
          {
            id: 2,
            value: "one thirty minute session per week of SLP servicess",
            check: false,
          },
          {
            id: 3,
            value: "two thirty minute sessions per week of SLP services",
            check: false,
          },
          {
            id: 3,
            value: "three thirty minute sessions per week of SLP services",
            check: false,
          },
          {
            id: 3,
            value: "one hour session per week of SLP services",
            check: false,
          },
          {
            id: 3,
            value: "two one-hour sessions per week of SLP services",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 29,
        subTitle: "Skills",
        question: "Which of these is name able to do?",
        select: "Multi",
        description: "Select 2 goals",
        answered: false,
        min: 2,
        max: 2,
        limit: false,
        options: [
          {
            id: 1,
            value: "use appropriate gestures",
            check: false,
            doHide: [0],
          },
          {
            id: 2,
            value: "use appropriate facial expressions",
            check: false,
            doHide: [1],
          },
          {
            id: 3,
            value: "Name objects correctly",
            replacement: false,
            check: false,
            doHide: [2],
          },
          {
            id: 3,
            value: "express P3 needs and wants",
            check: false,
            doHide: [3],
          },
          {
            id: 3,
            value: "ask questions",
            check: false,
            doHide: [4],
          },
          {
            id: 3,
            value: "answer yes and no questions",
            check: false,
            doHide: [5],
          },
          {
            id: 3,
            value: "answer 'wh' questions",
            check: false,
            doHide: [6],
          },
          {
            id: 3,
            value: "answer 'why' questions",
            check: false,
            doHide: [7],
          },
          {
            id: 3,
            value: "speak in full sentences",
            check: false,
            doHide: [8],
          },
          {
            id: 3,
            value: "write full sentences properly",
            check: false,
            doHide: [9],
          },
          {
            id: 3,
            value: "use correct grammar in speech",
            check: false,
            doHide: [10],
          },
          {
            id: 3,
            value: "use correct grammar in writing",
            check: false,
            doHide: [11],
          },
          {
            id: 3,
            value: "retell stories or events",
            check: false,
            doHide: [12],
          },
          {
            id: 3,
            value: "maintain a conversation with multiple exchanges",
            check: false,
            doHide: [13],
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 30,
        subTitle: "Struggles",
        question: "Which of these does name struggle with?",
        select: "Multi",
        description: "Select 2 to many",
        answered: false,
        min: 2,
        max: 14,
        limit: false,
        options: [
          {
            id: 1,
            value: "using appropriate gestures",
            check: false,
            isHidden: false,
          },
          {
            id: 2,
            value: "using appropriate facial expressions",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "naming objects correctly",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "expressing P3 needs and wants",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "asking questions",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "answering yes and no question",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "answering 'wh' questions",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "answering 'why' questions",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "speaking in full sentences",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "writing full sentences properly",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "using correct grammar in speech",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "using correct grammar in text",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "retelling stories or events",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "maintaining a conversation with multiple exchanges",
            check: false,
            isHidden: false,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 31,
        subTitle: "Strength",
        question: "Which of these is name’s strength?",
        select: "Multi",
        description: "Select 1 to 3",
        answered: false,
        min: 1,
        max: 3,
        limit: false,
        options: [
          {
            id: 1,
            value: "perceiving others’ emotions",
            check: false,
            doHide: [0],
          },
          {
            id: 2,
            value: "following single-step directions",
            check: false,
            doHide: [1],
          },
          {
            id: 3,
            value: "following multi-step directions",
            check: false,
            doHide: [1, 2],
          },
          {
            id: 3,
            value: "learning new words",
            check: false,
            doHide: [3],
          },
          {
            id: 3,
            value: "understanding new concepts and ideas",
            check: false,
            doHide: [4],
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 32,
        subTitle: "Weakness",
        question: "Which of these is name's weakness? ",
        select: "Multi",
        description: "Select 1 to 3",
        answered: false,
        min: 1,
        max: 3,
        limit: false,
        options: [
          {
            id: 1,
            value: "perceiving others’ emotions",
            check: false,
            isHidden: false,
          },
          {
            id: 2,
            value: "following single-step directions",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "following multi-step directions",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "learning new words",
            check: false,
            isHidden: false,
          },
          {
            id: 3,
            value: "understanding new concepts and ideas",
            check: false,
            isHidden: false,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Social-emotional",
    questions: [
      {
        isUpdated: false,
        questionID: 33,
        subTitle: "Delays",
        question: "In which areas does name display delays?",
        select: "Multi",
        description: "Select 1 to 3",
        min: 1,
        max: 3,
        limit: false,
        answered: false,
        options: [
          {
            id: 1,
            value: "compliance",
            check: false,
            level: 1,
          },
          {
            id: 2,
            value: "emotional regulation",
            check: false,
            level: 2,
          },
          {
            id: 3,
            value: "social skills",
            check: false,
            level: 3,
          },
          {
            id: 3,
            value: "none",
            check: false,
            level: 0,
          },
        ],
      },
      {
        isUpdated: false,
        questionID: 34,
        subTitle: "Skills",
        question: "How would you rate name's delays in social-emotional skills?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        description: "Select 1",
        answered: false,
        options: [
          {
            id: 1,
            value: "minimal delays",
            check: false,
          },
          {
            id: 2,
            value: "moderate delays",
            check: false,
          },
          {
            id: 3,
            value: "severe delays",
            check: false,
          },
        ],
      },
      {
        isUpdated: false,
        limit: false,
        questionID: 35,
        subTitle: "Concerns",
        question: "Rate name's progress in each area of concern",
        select: "Accordian",
        description: "Select 1 answer per section",
        dependQuestion: 0,
        answered: false,
        goalQues: [],
        sublimit: 1,
        suberGoals: [
          {
            title: "Compliance",
            level: 1,
            subGoals: ["slight improvement", "moderate improvement", "significant improvement"],
          },
          {
            title: "Emotional Regulation",
            level: 2,
            subGoals: ["minimal growth", "moderate growth", "significant growth"],
          },
          {
            title: "Social skills",
            level: 3,
            subGoals: ["small advancement", "moderate advancement", "major advancement"],
          },
        ],
      },
      {
        isUpdated: false,
        limit: false,
        questionID: 36,
        subTitle: "Improvements",
        description: "Select 1 answer per section",
        question: "Identify areas of improvement",
        select: "Accordian",
        dependQuestion: 0,
        answered: false,
        sublimit: 1,
        goalQues: [],
        suberGoals: [
          {
            title: "Which improvements were noticed in Compliance?",
            level: 1,
            subGoals: [
              "complying with teacher requests P1 agrees with",
              "complying with the school rules P1 agrees with",
              "complying with principal requests P1 agrees with",
            ],
          },
          {
            title: "Which improvements were noticed in Emotional Regulation?",
            level: 2,
            subGoals: ["recognizing P3 emotions", "labeling P3 emotions", "verbalizing P3 feelings"],
          },
          {
            title: "Which improvements were noticed in Social Interaction?",
            level: 3,
            subGoals: ["communicate with peers", "take turns with peers", "negotiate with peers"],
          },
        ],
      },
      {
        isUpdated: false,
        limit: false,
        questionID: 37,
        subTitle: "Struggles",
        description: "Select minimum 2 answers on each section",
        question: "Identify areas of struggle",
        select: "Accordian",
        dependQuestion: 0,
        answered: false,
        goalQues: [],
        suberGoals: [
          {
            title: "Which areas are still lacking in compliance?",
            level: 1,
            subGoals: [
              {
                title: "complying with teacher requests P1 agrees with",
                hideIf: ["complying with teacher requests P1 agrees with"],
              },
              {
                title: "complying with school rules even if P1 agrees with it",
                hideIf: ["complying with the school rules P1 agrees with"],
              },
              {
                title: "complying with principal requests even if P1 agrees with it",
                hideIf: ["complying with principal requests P1 agrees with"],
              },
              {
                title: "complying with teacher requests P1 disagrees with",
                hideIf: [],
              },
              {
                title: "complying with school rules P1 disagrees with",
                hideIf: [],
              },
              {
                title: "complying with principal requests P1 disagrees with",
                hideIf: [],
              },
            ],
          },
          {
            title: "Which areas are still lacking in emotional regulation?",
            level: 2,
            subGoals: [
              {
                title: "recognizing P3 emotions",
                hideIf: ["recognizing P3 emotions"],
              },
              {
                title: "labeling P3 emotions",
                hideIf: ["labeling P3 emotions"],
              },
              {
                title: "verbalizing P3 feelings",
                hideIf: ["verbalizing P3 feelings"],
              },
              {
                title: "expressing P3 emotions in an appropriate manner",
                hideIf: [],
              },
              {
                title: "avoiding temper tantrums",
                hideIf: [],
              },
              {
                title: "refraining from inappropriate behavior when frustrated or upset",
                hideIf: [],
              },
              {
                title: "relaxing strategies when angry or unhappy",
                hideIf: [],
              },
            ],
          },
          {
            title: "Which areas are still lacking in social interaction?",
            level: 3,
            subGoals: [
              {
                title: "communicate effectively with peers",
                hideIf: ["communicate with peers"],
              },
              {
                title: "take turns with peers",
                hideIf: ["take turns with peers"],
              },
              {
                title: "negotiate with peers",
                hideIf: ["negotiate with peers"],
              },
              {
                title: "initiate conversation with peers",
                hideIf: [],
              },
              {
                title: "initiate activities with peers",
                hideIf: [],
              },
              {
                title: "follow a lead during play",
                hideIf: [],
              },
              {
                title: "maintain eye contact",
                hideIf: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Summary",
    questions: [
      {
        isUpdated: false,
        questionID: 38,
        subTitle: "Benefits",
        description: "Select 1",
        question: "How would you describe name's range of struggles overall?",
        select: "Multi",
        min: 1,
        max: 1,
        limit: false,
        answered: false,
        options: [
          {
            id: 2,
            value: "all academic domains",
            check: false,
          },
          {
            id: 3,
            value: "many academic domains",
            check: false,
          },
          {
            id: 3,
            value: "some academic domains",
            check: false,
          },
          {
            id: 3,
            value: "many academic and social-emotional domains",
            check: false,
          },
        ],
      },
    ],
  },
];
