import './App.css';
import FullLayout from './layout/FullLayout';

function App() {
  return (
    <div >
      <FullLayout />
    </div>
  );
}

export default App;
